import { Text, View } from '@react-pdf/renderer';

import { GenderName } from 'src/models/Gender.model';
import { handleNSI18Next } from 'src/models/Language.model';
import { Session } from 'src/models/Session.model';
import { Settings } from 'src/models/Settings.model';

import { cls } from 'src/utils/reactPdfStyles';

interface IInfoSectionProps {
  session: Session;
  settings: Settings;
}

const InfoSection: React.FC<IInfoSectionProps> = ({ session, settings }) => {
  const { handleReportT } = handleNSI18Next();

  return (
    <View>
      <View style={cls('hr my-2')} />

      <View style={cls('d-flex hstack justify-content-between my-2')}>
        <View>
          <Text style={cls('text-muted p-0 border-0 w-20')}>
            {handleReportT('info.patientID')}
          </Text>

          <Text style={cls('lead p-0 border-0')}>{session.id || ''}</Text>
        </View>

        <View>
          <Text style={cls('text-muted p-0 border-0 w-30')}>
            {handleReportT('info.patientName')}
          </Text>

          <Text style={cls('lead p-0 border-0')}>
            {session.firstName} {session.lastName}
          </Text>
        </View>

        <View>
          <Text style={cls('text-muted p-0 border-0 w-15')}>
            {handleReportT('info.dob')}
          </Text>

          <Text style={cls('lead p-0 border-0')}>{session.dob || ''}</Text>
        </View>

        <View>
          <Text style={cls('text-muted p-0 border-0 w-15')}>
            {settings.reportGender && <Text>{handleReportT('info.sex')}</Text>}
          </Text>

          <Text style={cls('lead p-0 border-0')}>
            {settings.reportGender && (
              <Text>
                {session.gender === undefined ? '' : GenderName(session.gender)}
              </Text>
            )}
          </Text>
        </View>

        <View>
          <Text style={cls('text-muted p-0 border-0 w-20')}>
            {handleReportT('info.dateAndTime')}
          </Text>

          <Text style={cls('lead p-0 border-0')}>
            <Text>{session.date}</Text>
            &nbsp;
            <Text style={cls('text-muted')}>{session.time}</Text>
          </Text>
        </View>
      </View>

      <View style={cls('hr my-2')} />
    </View>
  );
};

export default InfoSection;
