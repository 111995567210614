import { Button, Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NotFound = () => {
  const history = useHistory();
  return (
    <Container
      fluid
      className="main-content d-flex justify-content-center align-items-md-center flex-column gap-4"
    >
      <h1 className="lead title text-center">
        <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
        404
      </h1>
      <div className="text-center">
        <h2>PAGE NOT FOUND.</h2>
        <p>We Couldn't Find This Page</p>
      </div>

      <Button variant="optain" onClick={() => history.push('/')}>
        <FontAwesomeIcon icon={faTriangleExclamation} className="me-2" />
        Back home
      </Button>
    </Container>
  );
};

export default NotFound;
