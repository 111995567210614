import { ReportTemplate } from 'src/models/ReportTemplate.model';
import { ReportType } from 'src/models/ReportType.model';
import { Session } from 'src/models/Session.model';
import { Settings } from 'src/models/Settings.model';

import AssurePlusReport from '../reports/AssurePlus/AssurePlusReport';
import AssureReport from '../reports/AssurePlus/AssureReport';
import OptainReport from '../reports/Optain/OptainReport';

interface IReportRendererProps {
  show: boolean;
  session: Session;
  settings: Settings;
  type: ReportType;
  logo: string;
}

const ReportRenderer: React.FC<IReportRendererProps> = ({
  show,
  session,
  settings,
  type,
  logo,
}) => {
  return show ? (
    <>
      {(session?.template === ReportTemplate.ASSURE_LARGE_EYE ||
        session?.template === ReportTemplate.ASSURE_SMALL_EYE) && (
        <AssureReport
          session={session}
          settings={settings}
          type={type}
          logo={logo}
        ></AssureReport>
      )}
      {(session?.template === ReportTemplate.ASSUREPLUS_LARGE_EYE_CVD ||
        session?.template === ReportTemplate.ASSUREPLUS_SMALL_EYE_CVD) && (
        <AssurePlusReport
          session={session}
          settings={settings}
          type={type}
          logo={logo}
        ></AssurePlusReport>
      )}

      {session?.template === ReportTemplate.OPTAIN_SMALL_DR && (
        <OptainReport session={session} settings={settings}></OptainReport>
      )}
    </>
  ) : (
    <></>
  );
};

export default ReportRenderer;
