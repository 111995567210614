import { Col, Row, Spinner, Stack } from 'react-bootstrap';

import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useNotifierStore } from 'src/store/useNotifierStore';

import {
  BackendStatus,
  ModelStatus,
} from 'src/models/GalenData/DeviceDataView.model';

const modelNameMapping: Record<
  keyof Omit<BackendStatus, 'GlobalStatus' | 'SessionId'>,
  string
> = {
  DrusStatus: 'Diabetic Retinopathy',
  DreuStatus: 'Diabetic Retinopathy',
  AmdeuStatus: 'Age-related Macular Degeneration',
  GlaucomaeuStatus: 'Glaucoma',
  CvdeuStatus: 'Cardiovascular Disease',
  DreuSmallStatus: 'Diabetic Retinopathy',
  AmdeuSmallStatus: 'Age-related Macular Degeneration',
  GlaucomaeuSmallStatus: 'Glaucoma',
  CvdeuSmallStatus: 'Cardiovascular Disease',
  VesselSegStatus: 'Vessel Segmentation',
};

export default function ModelStack({
  name,
}: {
  name: keyof Omit<BackendStatus, 'GlobalStatus' | 'SessionId'>;
}) {
  const textMapping: Record<ModelStatus, string> = {
    [ModelStatus.NA]: 'Awaiting Upload',
    [ModelStatus.Triggered]: 'Queued',
    [ModelStatus.Running]: 'Running',
    [ModelStatus.Complete]: 'Complete',
    [ModelStatus.Failed]: 'Failed',
  };

  const { status } = useNotifierStore();
  const statusValue = status[name].value;

  return (
    <Stack gap={3}>
      <Row>
        <Col sm={1}>
          {statusValue === ModelStatus.Complete ||
          statusValue === ModelStatus.Failed ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-optain-900" />
          ) : (
            <Spinner variant="optain" size="sm" />
          )}
        </Col>
        <Col sm={8}>{modelNameMapping[name]}</Col>
        <Col sm={3} className="text-secondary-400 text-end">
          {textMapping[statusValue]}
        </Col>
      </Row>
    </Stack>
  );
}
