import { Dispatch, SetStateAction } from 'react';

import WarnNoConnectedCameraModal from 'src/components/custom_components/WarnNoConnectedCameraModal';
import ReportViewer from 'src/components/ReportViewer';

import { Session } from 'src/models/Session.model';
import { Settings } from 'src/models/Settings.model';

import ConfirmPatientInformationModal from '../ConfirmPatientInformationModal';

interface ModalForActionsProps {
  session: Session;
  showViewer: boolean;
  currentSession: Session | undefined;
  handleSetShowViewer: (showViewer: boolean) => void;
  settings: Settings;
  shouldShowWarnNoConnectedCameraModal: boolean;
  setSession: Dispatch<SetStateAction<Session>>;
  isShowConfirmModal: boolean;
  handleSetIsShowConfirmModal: (isShowConfirmModal: boolean) => void;
}

const ModalForActions = ({
  session,
  showViewer,
  currentSession,
  handleSetShowViewer,
  settings,
  shouldShowWarnNoConnectedCameraModal,
  setSession,
  isShowConfirmModal,
  handleSetIsShowConfirmModal,
}: ModalForActionsProps) => {
  return (
    <>
      {currentSession && (
        <ReportViewer
          show={showViewer}
          setShow={handleSetShowViewer}
          session={currentSession}
          settings={settings}
        />
      )}

      <ConfirmPatientInformationModal
        session={session}
        setSession={setSession}
        isShowConfirmModal={isShowConfirmModal}
        handleSetIsShowConfirmModal={handleSetIsShowConfirmModal}
      />

      <WarnNoConnectedCameraModal
        isVisible={shouldShowWarnNoConnectedCameraModal}
      />
    </>
  );
};

export default ModalForActions;
