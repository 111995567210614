// each report template is fixed, list all posibilities
enum ReportTemplate {
  ASSURE_LARGE_EYE = 'assure_large_eye', // clinical and patient in one report
  ASSURE_SMALL_EYE = 'assure_small_eye', // clinical and patient in one report

  ASSUREPLUS_LARGE_EYE_CVD = 'assureplus_large_eye_cvd', // clinical and patient in one report
  ASSUREPLUS_SMALL_EYE_CVD = 'assureplus_small_eye_cvd', // clinical and patient in one report

  OPTAIN_SMALL_DR = 'optain_small_dr',
}

const ReportTemplateName = (template: ReportTemplate): string => {
  switch (template) {
    case ReportTemplate.ASSURE_LARGE_EYE:
    case ReportTemplate.ASSURE_SMALL_EYE: {
      return 'Eye diseases';
    }

    case ReportTemplate.ASSUREPLUS_LARGE_EYE_CVD:
    case ReportTemplate.ASSUREPLUS_SMALL_EYE_CVD: {
      return 'Eye diseases + Cardiovascular health';
    }

    case ReportTemplate.OPTAIN_SMALL_DR: {
      return 'Diabetic Retinopathy';
    }

    default: {
      return `Unknown template: ${template}`;
    }
  }
};

export { ReportTemplate, ReportTemplateName };
