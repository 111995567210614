import { create } from 'zustand';

import { DeviceDataValue } from 'src/models/GalenData/DeviceDataValue.model';
import {
  BackendStatus,
  GlobalStatus,
  ModelStatus,
} from 'src/models/GalenData/DeviceDataView.model';
import { ModelEnum } from 'src/models/Model.model';

type State = {
  waiting: boolean;
  deviceDataId: string;
  sessionId: string;
  status: BackendStatus;
  models: ModelEnum[];
};

type Actions = {
  setdeviceDataId: (waiting: boolean, deviceDataId: string) => void;
  setWaiting: (waiting: boolean) => void;
  setSessionId: (sessionId: string) => void;
  setStatus: (status: BackendStatus) => void;
  setModels: (models: ModelEnum[]) => void;
};

const defaultModelStatus: DeviceDataValue<ModelStatus> = {
  value: ModelStatus.NA,
};

export const defaultNotifierStatus: BackendStatus = {
  GlobalStatus: { value: GlobalStatus.Null },
  SessionId: { value: '' },
  DrusStatus: defaultModelStatus,
  DreuStatus: defaultModelStatus,
  AmdeuStatus: defaultModelStatus,
  GlaucomaeuStatus: defaultModelStatus,
  CvdeuStatus: defaultModelStatus,
  AmdeuSmallStatus: defaultModelStatus,
  DreuSmallStatus: defaultModelStatus,
  GlaucomaeuSmallStatus: defaultModelStatus,
  CvdeuSmallStatus: defaultModelStatus,
  VesselSegStatus: defaultModelStatus,
};

export const useNotifierStore = create<State & Actions>((set) => ({
  waiting: false,
  deviceDataId: '',
  sessionId: '',
  status: defaultNotifierStatus,
  models: [],
  setdeviceDataId: (waiting, deviceDataId) => set({ waiting, deviceDataId }),
  setWaiting: (waiting) => set({ waiting }),
  setSessionId: (sessionId) => set({ sessionId }),
  setStatus: (status) => set({ status }),
  setModels: (models) => set({ models }),
}));
