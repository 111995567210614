import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import useBoundStore from 'src/store/useBoundStore';
import {
  defaultNotifierStatus,
  useNotifierStore,
} from 'src/store/useNotifierStore';

import { Api } from 'src/models/Api.model';
import { CameraApi } from 'src/models/Camera/CameraApi.model';
import { getModels } from 'src/models/Product.model';
import { ReportTemplate } from 'src/models/ReportTemplate.model';
import { EyesPositionAbbr, Session } from 'src/models/Session.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

type IUseRetake = {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;

  handleSetLeftUrl: (leftUrl: string) => void;
  handleSetRightUrl: (rightUrl: string) => void;
};

const upload = (imageUrl: string, callback: any) => {
  callback(imageUrl);
};

export default function useRetake({
  session,
  setSession,
  handleSetLeftUrl,
  handleSetRightUrl,
}: IUseRetake) {
  const [capturing, setCapturing] = useState(false);

  const { product, camera, practiceId, userId } = useBoundStore();

  const { setStatus, setdeviceDataId, setModels, setSessionId } =
    useNotifierStore();

  const handleRetakeSession = useCallback(
    async (updatedSession: Session) => {
      setStatus(defaultNotifierStatus);

      // retake session
      try {
        const models = getModels(
          product,
          camera,
          updatedSession.template || ReportTemplate.OPTAIN_SMALL_DR,
        );

        setModels(models);

        const tempSessionId = uuidv4();
        setSessionId(tempSessionId);

        const iid = await Api.remote.retake_session(
          practiceId,
          userId,
          updatedSession,
          models,
          tempSessionId,
        );

        setSession(updatedSession);

        setdeviceDataId(true, iid);

        // when session is completed,
        // handleCloseProgress will be called
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);
        Api.alertBox('Error', errorMessage);
      }
    },
    [
      camera,
      practiceId,
      product,
      setModels,
      setSession,
      setSessionId,
      setStatus,
      setdeviceDataId,
      userId,
    ],
  );

  const handleAnalyzeLeftEye = (leftEyeImage: string) => {
    const updatedSession = { ...session };

    updatedSession.leftImageFile = leftEyeImage;
    updatedSession.leftResults = [];
    updatedSession.leftSegmentations = [];
    updatedSession.leftImageFileName = leftEyeImage.split('/').pop() ?? '';

    // force re-grade
    updatedSession.graded = false;

    handleRetakeSession(updatedSession);
  };

  const handleAnalyzeRightEye = (rightEyeImage: string) => {
    const updatedSession = { ...session };

    updatedSession.rightImageFile = rightEyeImage;
    updatedSession.rightResults = [];
    updatedSession.rightSegmentations = [];
    updatedSession.rightImageFileName = rightEyeImage.split('/').pop() ?? '';

    // force re-grade
    updatedSession.graded = false;

    handleRetakeSession(updatedSession);
  };

  const handleAnalyzeBothEyes = (
    leftEyeImage: string,
    rightEyeImage: string,
  ) => {
    const updatedSession = { ...session };

    updatedSession.leftImageFile = leftEyeImage;
    updatedSession.leftResults = [];
    updatedSession.leftSegmentations = [];
    updatedSession.leftImageFileName = leftEyeImage.split('/').pop() ?? '';

    updatedSession.rightImageFile = rightEyeImage;
    updatedSession.rightResults = [];
    updatedSession.rightSegmentations = [];
    updatedSession.rightImageFileName = rightEyeImage.split('/').pop() ?? '';

    // force re-grade
    updatedSession.graded = false;

    handleRetakeSession(updatedSession);
  };

  const uploadAnalyzeLeftEye = (leftUrl: string) => {
    upload(leftUrl, (file: string) => {
      handleAnalyzeLeftEye(file);
    });
  };

  const uploadAnalyzeRightEye = (rightUrl: string) => {
    upload(rightUrl, (file: string) => {
      handleAnalyzeRightEye(file);
    });
  };

  const uploadAnalyzeBothEyes = (leftUrl: string, rightUrl: string) => {
    upload(leftUrl, (leftFile: string) => {
      upload(rightUrl, (rightFile: string) => {
        handleAnalyzeBothEyes(leftFile, rightFile);
      });
    });
  };

  const retakeLeftEye = () => {
    setCapturing(true);
    CameraApi.captureLeftEye((err, url) => {
      if (err === undefined) {
        handleSetLeftUrl(url);

        // wait for the image to be ready
        setTimeout(() => {
          upload(url, (file: string) => {
            handleAnalyzeLeftEye(file);
            setCapturing(false);
          });
        }, 1000);
      }
    });
  };

  const retakeRightEye = () => {
    setCapturing(true);
    CameraApi.captureRightEye((err, url) => {
      if (err === undefined) {
        handleSetRightUrl(url);

        // wait for the image to be ready
        setTimeout(() => {
          upload(url, (file: string) => {
            handleAnalyzeRightEye(file);
            setCapturing(false);
          });
        }, 1000);

        setCapturing(false);
      }
    });
  };

  const retakeBothEyes = () => {
    const imagesContainer: { [EyesPositionAbbr.LEFT]: string } = {
      [EyesPositionAbbr.LEFT]: '',
    };

    setCapturing(true);

    CameraApi.captureBothEyes(
      (err, url) => {
        // console.log('left image capture', err, url);
        if (err === undefined) {
          handleSetLeftUrl(url);

          // wait for the image to be ready
          setTimeout(() => {
            upload(url, (file: string) => {
              imagesContainer.OS = file;
            });
          }, 1000);
        }
      },
      (err, url) => {
        // console.log('right image capture', err, url);
        if (err === undefined) {
          handleSetRightUrl(url);

          // wait for the image to be ready
          setTimeout(() => {
            upload(url, (file: string) => {
              handleAnalyzeBothEyes(imagesContainer.OS, file);
              setCapturing(false);
            });
          }, 1000);
        }
        setCapturing(false);
      },
    );
  };

  return {
    capturing,
    retakeLeftEye,
    retakeRightEye,
    retakeBothEyes,
    uploadAnalyzeLeftEye,
    uploadAnalyzeRightEye,
    uploadAnalyzeBothEyes,
  };
}
