import * as React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { exists, t } from 'i18next';

import { Disease } from 'src/models/Disease.model';
import { handleNSI18Next, I18NextNS } from 'src/models/Language.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

interface IClassificationTableSectionProps {
  session: Session;
}

const ClassificationTableSection: React.FC<
  IClassificationTableSectionProps
> = ({ session }) => {
  const { handleReportT } = handleNSI18Next();

  // assureplus_small_eye_cvd
  const templateParts = (session.template || '').split('_');

  let diseases: string[] = [];

  if (templateParts.includes('eye')) {
    diseases = [...diseases, Disease.DR, Disease.AMD, Disease.GLAUCOMA];
  }

  if (templateParts.includes('cvd')) {
    diseases = [...diseases, Disease.CVD_RISK];
  }

  return (
    <View>
      <View style={cls('hr my-2')} />

      <Text style={cls('p')}>
        {handleReportT('classificationSection.description')}
      </Text>

      <View style={cls('d-flex hstack justify-content-between my-0')}>
        <View
          style={cls('td disease-column bg-secondary-400 lead text-center')}
        >
          <Text>{handleReportT('classificationSection.disease')}</Text>
        </View>

        <View
          style={cls('td output-definition-columns p-0 bg-secondary-400 lead')}
        >
          <View style={cls('d-flex hstack justify-content-between m-0')}>
            <View style={cls('td w-30 text-center')}>
              <Text>
                {handleReportT('classificationSection.productOutput')}
              </Text>
            </View>
            <View style={cls('td w-70 text-center')}>
              <Text>{handleReportT('classificationSection.definition')}</Text>
            </View>
          </View>
        </View>
      </View>

      {diseases.map((disease) => {
        const gradings = t(`classificationSection.${disease}.grading`, {
          ns: I18NextNS.REPORT,
          returnObjects: true,
        });

        const rows = [];
        for (const key in gradings) {
          const level = t(`classificationSection.${disease}.level.${key}`, {
            ns: I18NextNS.REPORT,
            returnObjects: true,
          }) as any;

          rows.push(
            <View
              style={cls('d-flex hstack justify-content-between m-0')}
              key={key}
            >
              <View
                style={cls(
                  'td w-30 border-end text-center justify-content-center',
                )}
              >
                <Text>
                  {handleReportT(
                    `classificationSection.${disease}.grading.${key}`,
                  )}
                </Text>
              </View>
              <View style={cls('td w-70')}>
                {Array.isArray(level) &&
                  level.map((line) => (
                    <Text style={cls('li')} key={line}>
                      {line}
                    </Text>
                  ))}

                {typeof level === 'string' && <Text>{level}</Text>}
              </View>
            </View>,
          );
        }

        const hasHeader = exists(`classificationSection.${disease}.header`, {
          ns: I18NextNS.REPORT,
        });
        const hasHeaderTitle = exists(
          `classificationSection.${disease}.header.title`,
          { ns: I18NextNS.REPORT },
        );

        const hasFooter = exists(`classificationSection.${disease}.footer`, {
          ns: I18NextNS.REPORT,
        });
        const hasFooterTitle = exists(
          `classificationSection.${disease}.footer.title`,
          { ns: I18NextNS.REPORT },
        );

        return (
          <View
            style={cls('d-flex hstack justify-content-between my-0')}
            key={disease}
          >
            <View
              style={cls(
                'td disease-column bg-secondary-200 justify-content-center',
              )}
            >
              <Text style={cls('lead')}>
                {handleReportT(`classificationSection.${disease}.name`)}
              </Text>
              <Text>
                {handleReportT(`classificationSection.${disease}.standard`)}
              </Text>
            </View>

            <View style={cls('td output-definition-columns p-0')}>
              {hasHeader && (
                <View style={cls('d-flex hstack justify-content-between m-0')}>
                  <View style={cls('td w-100')}>
                    {hasHeaderTitle && (
                      <Text style={cls('p semibold mx-0 mt-0')}>
                        {handleReportT(
                          `classificationSection.${disease}.header.title`,
                        )}
                      </Text>
                    )}
                    <Text style={cls('p i m-0')}>
                      {handleReportT(
                        `classificationSection.${disease}.header.content`,
                      )}
                    </Text>
                  </View>
                </View>
              )}

              {rows}

              {hasFooter && (
                <View style={cls('d-flex hstack justify-content-between m-0')}>
                  <View style={cls('td w-100')}>
                    {hasFooterTitle && (
                      <Text style={cls('p semibold mx-0 mt-0')}>
                        {handleReportT(
                          `classificationSection.${disease}.footer.title`,
                        )}
                      </Text>
                    )}
                    <Text style={cls('p i m-0')}>
                      {handleReportT(
                        `classificationSection.${disease}.footer.content`,
                      )}
                    </Text>
                  </View>
                </View>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default ClassificationTableSection;
