import { useEffect, useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';

import {
  faDownload,
  faInfoCircle,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pdf } from '@react-pdf/renderer';
import moment from 'moment';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { Language } from 'src/models/Language.model';
import { ReportTemplate } from 'src/models/ReportTemplate.model';
import { ReportType, ReportTypeName } from 'src/models/ReportType.model';
import { Session } from 'src/models/Session.model';
import { Settings } from 'src/models/Settings.model';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import ReportRenderer from './ReportRenderer';

// ref: https://github.com/wojtekmaj/react-pdf/issues/1811
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

// @ts-expect-error This does not exist outside of polyfill which this is doing
if (Promise.withResolvers === undefined) {
  if (window) {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    window.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
      });
      return { promise, resolve, reject };
    };
  } else {
    // @ts-expect-error This does not exist outside of polyfill which this is doing
    global.Promise.withResolvers = function () {
      let resolve, reject;
      const promise = new Promise((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
      });
      return { promise, resolve, reject };
    };
  }
}

interface IReportViewerProps {
  show: boolean;
  session: Session;
  settings: Settings;
  setShow: (showViewer: boolean) => void;
}

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('loadend', () => {
      // Resolve with the Base64 string (removing the prefix)
      const base64data = reader.result as string;
      resolve(base64data.split(',')[1]); // Returns the Base64 part only
    });

    reader.addEventListener('error', () => {
      reject(new Error('Failed to convert blob to Base64'));
    });

    reader.readAsDataURL(blob); // Read the Blob as a data URL
  });
}

const downloadFile = async (url: string, filename: string, blob: Blob) => {
  // use client api?
  if (window._cdvElectronIpc?.saveReportFile) {
    // fun saveReportFile(fileName: String, base64String: String): Uri?
    const encoded = await blobToBase64(blob);
    window._cdvElectronIpc.saveReportFile(filename, encoded);
    return;
  }

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.download = filename;
  document.body.append(a);
  a.click();
  window.URL.revokeObjectURL(url);
};

const ReportViewer = ({
  show,
  session,
  settings,
  setShow,
}: IReportViewerProps) => {
  const [type, setType] = useState(ReportType.CLINICAL);
  const [pdfUrl, setPdfUrl] = useState('');
  const [blobData, setBlobData] = useState<Blob>();
  const [numPages, setNumPages] = useState(0);

  const {
    i18n: { changeLanguage, language },
  } = useTranslation();

  const { userPermission, logo } = useBoundStore();

  const setTypeClinical = () => {
    setType(ReportType.CLINICAL);
  };

  const setTypePatient = () => {
    setType(ReportType.PATIENT);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handlePrintReport = async () => {
    try {
      const allowed = userPermission.print;

      if (allowed !== true) {
        Api.alertBox(
          'Error',
          'Failed to print the report. User permission denied.',
        );
        return;
      }

      // use client api?
      if (window._cdvElectronIpc?.printReportFile) {
        // fun printReportFile(base64String: String): Boolean
        const encoded = await blobToBase64(blobData!);
        window._cdvElectronIpc.printReportFile(encoded);
        return;
      }

      // print pdf canvas with jQuery printThis plugin
      window.$('canvas.react-pdf__Page__canvas').printThis({
        importCSS: false,
        formValues: false,
        removeScripts: true,
        canvas: true,
        debug: true, // Keep iframe for print preview
        loadCSS: '/css/print.css', // Css file for print layout
      });
    } catch (error) {
      Api.alertBox(
        'Error',
        `Failed to print the report. ${Api.formatException((error as Error).message)}`,
      );
    }
  };

  const handleDownloadReport = async () => {
    if (session.iid === undefined) {
      Api.alertBox(
        'Error',
        'Failed to download the report. Invalid Session ID.',
      );
      return;
    }

    try {
      const allowed = userPermission.print;

      if (allowed !== true) {
        Api.alertBox(
          'Error',
          'Failed to download the report. User permission denied.',
        );
        return;
      }

      // Assure Clinical Report 123 18-11-2022.pdf

      const title = `Assure ${ReportTypeName(type)} ${
        session.id || 'NO_ID'
      } ${moment().format('DD-MM-YYYY')}`;

      downloadFile(pdfUrl, title + '.pdf', blobData!);
    } catch (error) {
      Api.alertBox(
        'Error',
        `Failed to download the report. ${Api.formatException((error as Error).message)}`,
      );
    }
  };

  const onDocumentLoadSuccess = (obj: DocumentCallback) => {
    setNumPages(obj.numPages);
  };

  // prepare pdfUrl
  useEffect(() => {
    if (session !== undefined) {
      const preparePdf = async () => {
        const blob = await pdf(
          <ReportRenderer
            show={true}
            session={session}
            settings={settings}
            type={type}
            logo={logo}
          />,
        ).toBlob();
        setBlobData(blob);

        // Create a Blob URL from the PDF Blob
        const blobUrl = URL.createObjectURL(blob);
        setPdfUrl(blobUrl);
      };

      preparePdf();
    }
  }, [session, settings, type, logo, language]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size="xl"
      scrollable={true}
      fullscreen="xl-down"
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ height: '55px' }}>
          <span style={{ lineHeight: '55px' }}>View Report</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="report-content p-0"
        style={{ width: '100%', height: '100vh' }}
      >
        {pdfUrl ? (
          <Document
            file={pdfUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <div className="p-4 w-100 text-center">
                <Spinner className="mx-5 my-5" variant="optain" />
              </div>
            }
            error={
              <div className="p-4 w-100 text-center">
                <FontAwesomeIcon icon={faInfoCircle} />
                &nbsp;Failed to load the report.
              </div>
            }
            noData=""
          >
            {numPages &&
              Array.from({ length: numPages }, (_, index) => index + 1).map(
                (pageNumber) => (
                  <Page
                    key={pageNumber}
                    pageNumber={pageNumber}
                    width={1080}
                    loading=""
                  />
                ),
              )}
          </Document>
        ) : (
          <div className="p-4 w-100 text-center">
            <Spinner className="mx-5 my-5" variant="optain" />
          </div>
        )}
      </Modal.Body>

      <Modal.Footer>
        {(session.template === ReportTemplate.ASSURE_LARGE_EYE ||
          session.template === ReportTemplate.ASSURE_SMALL_EYE ||
          session.template === ReportTemplate.ASSUREPLUS_LARGE_EYE_CVD ||
          session.template === ReportTemplate.ASSUREPLUS_SMALL_EYE_CVD) && (
          <>
            <DropdownButton
              variant="light"
              title={
                type === ReportType.CLINICAL
                  ? ReportTypeName(ReportType.CLINICAL) + ' '
                  : ReportTypeName(ReportType.PATIENT) + ' '
              }
            >
              <Dropdown.Item onClick={setTypeClinical}>
                {ReportTypeName(ReportType.CLINICAL)}
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={setTypePatient}>
                {ReportTypeName(ReportType.PATIENT)}
              </Dropdown.Item>
            </DropdownButton>

            <Dropdown>
              <Dropdown.Toggle variant="outline-dark" id="dropdown-basic">
                {language}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.values(Language).map((lang) => (
                  <Dropdown.Item
                    key={lang}
                    active={language === lang}
                    onClick={() => changeLanguage(lang)}
                  >
                    {lang}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}

        <Button
          variant="dark"
          className="w-200px"
          onClick={handleDownloadReport}
          data-test="download-btn"
        >
          <FontAwesomeIcon icon={faDownload} className="me-1"></FontAwesomeIcon>
          Download
        </Button>

        <Button variant="dark" className="w-200px" onClick={handlePrintReport}>
          <FontAwesomeIcon icon={faPrint} className="me-1"></FontAwesomeIcon>
          Print
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReportViewer;
