import { Accordion, Modal } from 'react-bootstrap';

import { useNotifierStore } from 'src/store/useNotifierStore';

import { BackendStatus } from 'src/models/GalenData/DeviceDataView.model';
import { ModelEnum } from 'src/models/Model.model';
import { Session } from 'src/models/Session.model';

import GlobalStack from './GlobalStack';
import ModelStack from './ModelStack';

const modelStatusMapping: Record<
  ModelEnum,
  keyof Omit<BackendStatus, 'GlobalStatus' | 'SessionId'>
> = {
  [ModelEnum.DR_US]: 'DrusStatus',
  [ModelEnum.DR_EU]: 'DreuStatus',
  [ModelEnum.AMD_EU]: 'AmdeuStatus',
  [ModelEnum.GLAUCOMA_EU]: 'GlaucomaeuStatus',
  [ModelEnum.CVD_EU]: 'CvdeuStatus',
  [ModelEnum.DR_EU_SMALL]: 'DreuSmallStatus',
  [ModelEnum.AMD_EU_SMALL]: 'AmdeuSmallStatus',
  [ModelEnum.GLAUCOMA_EU_SMALL]: 'GlaucomaeuSmallStatus',
  [ModelEnum.CVD_EU_SMALL]: 'CvdeuSmallStatus',
  [ModelEnum.VESSEL_SEGMENTATION]: 'VesselSegStatus',
};

interface ProgressModalBodyProps {
  session: Session;
  shouldShowDilationRecommendation: boolean;
  shouldShowReachMaxRecommendedAttempts: boolean;
}

const ProgressModalBody = ({
  session,
  shouldShowDilationRecommendation,
  shouldShowReachMaxRecommendedAttempts,
}: ProgressModalBodyProps) => {
  const { models, status } = useNotifierStore();

  return (
    <Modal.Body>
      <Accordion className="mb-2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <GlobalStack session={session} />
          </Accordion.Header>

          <Accordion.Body>
            {models.map(
              (model, index) =>
                status[modelStatusMapping[model]]?.value && (
                  <ModelStack key={index} name={modelStatusMapping[model]} />
                ),
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {shouldShowDilationRecommendation && (
        <i className="text-optain">
          Dilation is recommended for continued imaging
        </i>
      )}
      {shouldShowReachMaxRecommendedAttempts && (
        <i className="text-danger">
          Reached maximum recommended imaging attempts
        </i>
      )}
    </Modal.Body>
  );
};

export default ProgressModalBody;
