import { Col, Row, Spinner, Stack } from 'react-bootstrap';

import {
  faCheckCircle,
  faXmarkCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useNotifierStore } from 'src/store/useNotifierStore';

import { GlobalStatus } from 'src/models/GalenData/DeviceDataView.model';
import { Session } from 'src/models/Session.model';

import isGradable from '../../utils/isGradable';

export default function GlobalStack({ session }: { session: Session }) {
  const textMapping: Record<GlobalStatus, string> = {
    [GlobalStatus.Null]: 'Awaiting Upload',
    [GlobalStatus.Failed]: 'Server Error',
    [GlobalStatus.InitializerStarted]: 'Initializing',
    [GlobalStatus.InitializerEnded]: 'Initialized',
    [GlobalStatus.ModelsStarted]: 'Analyzing',
    [GlobalStatus.ReportStarted]: 'Generating Results',
    [GlobalStatus.ReportEnd]: 'Complete',
  };

  const StatusText = (globalStatus: GlobalStatus, gradable: boolean) => {
    if (!gradable) {
      return (
        <Col sm={3} className="text-danger text-end">
          Ungradable
        </Col>
      );
    }

    if (globalStatus === GlobalStatus.Failed) {
      return (
        <Col sm={3} className="text-danger text-end">
          Server Error
        </Col>
      );
    }

    return (
      <Col sm={3} className="text-secondary-400 text-end">
        {textMapping[globalStatus]}
      </Col>
    );
  };

  const { status } = useNotifierStore();

  const globalStatus = status.GlobalStatus.value;

  const gradable = isGradable(globalStatus, session);

  return (
    <Stack gap={3}>
      <Row>
        <Col sm={1}>
          {globalStatus === GlobalStatus.Failed || !gradable ? (
            <FontAwesomeIcon icon={faXmarkCircle} className="text-danger" />
          ) : globalStatus === GlobalStatus.ReportEnd && gradable ? (
            <FontAwesomeIcon icon={faCheckCircle} className="text-optain-900" />
          ) : (
            <Spinner variant="optain" size="sm" />
          )}
        </Col>
        <Col sm={8}>
          <strong>System Status</strong>
        </Col>
        {StatusText(globalStatus, gradable)}
      </Row>
    </Stack>
  );
}
