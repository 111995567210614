import moment from 'moment';

import { Appointment } from './Appointment.model';
import { Gender } from './Gender.model';
import { ReportTemplate } from './ReportTemplate.model';
import { Result } from './Result.model';
import { Segmentation } from './Segmentation.model';

export interface SessionBase {
  // username: string, // in database

  iid: string;
  username: string;

  id: string;
  firstName: string;
  lastName: string;
  age?: number;
  gender?: Gender;
  dob?: string;

  date: string; // YYYY-MM-DD
  time: string; // HH:mm:ss

  leftImageFile?: string;
  rightImageFile?: string;

  graded: boolean;

  leftResults?: Result[];
  rightResults?: Result[];

  leftSegmentations?: Segmentation[];
  rightSegmentations?: Segmentation[];

  notes?: string;
  appointment?: Appointment;

  createdAt: Date;

  code?: string;

  leftEyeDilatedTime?: Date;
  rightEyeDilatedTime?: Date;

  completedTime?: Date;
  completedReason?: CompletedReasonEnum;

  leftImageFileName: string;
  rightImageFileName: string;

  template?: ReportTemplate;
  ownerId?: string;

  sessionId: string;
}

export interface Session extends SessionBase {
  retakeSessions?: SessionBase[];
  orderId?: string;
}

export enum CompletedReasonEnum {
  END_SESSION = 'End Session',
  COMPLETED_EXAM = 'Complete Exam',
  AUTO_COMPLETE = 'Auto Complete',
}

export enum SessionStatusEnum {
  IN_PROGRESS = 'In Progress',
  EXAM_ENDED = 'Exam Ended',
  EXAM_SUCCESSFUL = 'Exam Successful',
  UPLOADING = 'Uploading',
  UPLOAD_FAILED = 'Upload Failed',
  QUEUED = 'Queued',
}

export enum EyesPosition {
  LEFT = 'left',
  RIGHT = 'right',
  BOTH = 'both',
}

export enum EyesPositionAbbr {
  LEFT = 'OS',
  RIGHT = 'OD',
}

export const PatientInfoKeyMapOptain = {
  id: 'MRN',
  firstName: 'First Name',
  lastName: 'Last Name',
  dob: 'DOB',
};

export const DilatedInformation = {
  leftEyeDilatedTime: 'Left Eye Dilated',
  rightEyeDilatedTime: 'Right Eye Dilated',
};

export const PatientInfoKeyMapAssure = {
  id: 'Patient ID',
  firstName: 'First Name',
  lastName: 'Last Name',
  age: 'Age',
  gender: 'Sex',
};

export const pendingStatusText = (session: Session): string => {
  // Both files have been uploaded
  if (session.leftImageFile && session.rightImageFile) {
    return SessionStatusEnum.QUEUED;
  }

  const progressText =
    session.leftImageFile || session.rightImageFile ? '(1/2)' : '(0/2)';

  // Session was created less than 1 minute ago
  if (moment().diff(session.createdAt, 'seconds') < 60) {
    return `${SessionStatusEnum.UPLOADING} ${progressText}`;
  }

  // Upload is incomplete and has exceeded 1 minute
  return `${SessionStatusEnum.UPLOAD_FAILED} ${progressText}`;
};
