import React, { useEffect, useRef } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import _ from 'lodash';

import useBoundStore from 'src/store/useBoundStore';

import { ApplicationInstance } from 'src/models/Application.model';
import { isValidCvdAge } from 'src/models/CvdLevel.model';
import { Gender } from 'src/models/Gender.model';
import {
  ReportTemplate,
  ReportTemplateName,
} from 'src/models/ReportTemplate.model';
import { Session } from 'src/models/Session.model';

export enum DilationEnum {
  NotDilated = 'Not Dilated',
  Dilated = 'Dilated',
}

const isCvdTemplateWithInvalidAge = (
  template: ReportTemplate,
  age?: number,
): boolean => {
  return template.includes('cvd') && age !== undefined && !isValidCvdAge(age);
};

type PatientInformationConfirmationModalProps = {
  formValues: Partial<Session>;
  isShowConfirmModal: boolean;
  handleConfirmPatientInfo: (data: any) => void;
  handleCloseConfirmModal: () => void;
  InfoConfirmationKeyMap: { [key in keyof Partial<Session>]?: string };
};

export default function PatientInformationConfirmationModal({
  formValues,
  isShowConfirmModal,
  handleConfirmPatientInfo,
  handleCloseConfirmModal,
  InfoConfirmationKeyMap,
}: PatientInformationConfirmationModalProps) {
  const { product, camera } = useBoundStore();

  const formRef = useRef<HTMLFormElement>(null);

  const { handleSubmit, register, watch, setValue } = useForm({
    defaultValues: {
      leftEyeDilatedTime: 'Not Dilated',
      rightEyeDilatedTime: 'Not Dilated',
      template: ApplicationInstance(product, camera).templates[0],
      gender: Gender.MALE,
    },
  });

  useEffect(() => {
    setValue('template', ApplicationInstance(product, camera).templates[0]);
  }, [camera, product, setValue]);

  const isContainCVDAlgorithm = watch('template')?.includes('cvd');

  const handleConfirm = () => {
    if (formRef.current) {
      formRef.current.requestSubmit(); // Trigger the form submit programmatically
    }
  };

  return (
    <Modal
      show={isShowConfirmModal}
      onHide={handleCloseConfirmModal}
      backdrop="static"
      animation={false}
      scrollable={true}
      fullscreen="xl-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Patient Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form ref={formRef} onSubmit={handleSubmit(handleConfirmPatientInfo)}>
          <Card className="mb-4">
            <Card.Body className="py-2">
              {(
                ['dob', 'lastName', 'firstName', 'id'] as Array<
                  keyof Omit<
                    Session,
                    'leftEyeDilatedTime' | 'rightEyeDilatedTime' | 'template'
                  >
                >
              ).map((objKey) => (
                <Form.Group as={Row} className="mb-3" key={objKey}>
                  <Form.Label column sm="4">
                    {InfoConfirmationKeyMap[objKey]}
                  </Form.Label>
                  <Col sm="8">{formValues[objKey]?.toString()}</Col>
                </Form.Group>
              ))}
            </Card.Body>
          </Card>
          <div className="border rounded p-3 border-info">
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Left Eye Dilation
              </Form.Label>

              <Col>
                <Form.Group className="gap-2">
                  <Form.Check
                    type="radio"
                    label="Not Dilated"
                    value="Not Dilated"
                    {...register('leftEyeDilatedTime')}
                  />
                  <Form.Check
                    type="radio"
                    label="Dilated"
                    value="Dilated"
                    {...register('leftEyeDilatedTime')}
                  />
                </Form.Group>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="4">
                Right Eye Dilation
              </Form.Label>
              <Col>
                <Form.Group className="gap-2">
                  <Form.Check
                    type="radio"
                    label="Not Dilated"
                    value="Not Dilated"
                    {...register('rightEyeDilatedTime')}
                  />
                  <Form.Check
                    type="radio"
                    label="Dilated"
                    value="Dilated"
                    {...register('rightEyeDilatedTime')}
                  />
                </Form.Group>
              </Col>
            </Form.Group>

            <Row className="mb-4">
              <Col>
                <Form.Group as={Row}>
                  <Col md={4}>
                    <Form.Label>Select Report Type</Form.Label>
                  </Col>
                  <Col>
                    {ApplicationInstance(product, camera).templates.map(
                      (template) =>
                        isCvdTemplateWithInvalidAge(
                          template,
                          formValues.age,
                        ) ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip>
                                Cardiovascular analysis is for ages 40 to 80
                                only.
                              </Tooltip>
                            }
                          >
                            <span className="d-inline-block">
                              <Form.Check
                                key={template}
                                type="radio"
                                label={ReportTemplateName(template)}
                                value={template}
                                disabled={true}
                                {...register('template')}
                              />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <Form.Check
                            key={template}
                            type="radio"
                            label={ReportTemplateName(template)}
                            value={template}
                            {...register('template')}
                          />
                        ),
                    )}
                  </Col>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {isContainCVDAlgorithm && (
                  <Form.Group as={Row}>
                    <Col md={4}>
                      <Form.Label>Sex</Form.Label>
                    </Col>
                    <Col md={8}>
                      <Stack>
                        {Object.values(Gender).map((gender) => (
                          <Form.Check
                            key={gender}
                            type="radio"
                            label={_.capitalize(gender)}
                            value={gender}
                            {...register('gender')}
                          />
                        ))}
                      </Stack>
                    </Col>
                  </Form.Group>
                )}
              </Col>
            </Row>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="outline-primary"
          className="text-black w-200px"
          onClick={handleCloseConfirmModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          className="text-black w-200px"
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
