import { ReportTemplate } from '../ReportTemplate.model';
import { DeviceDataValue } from './DeviceDataValue.model';

export enum OrderStatusEnum {
  OPEN = 'Open',
  COMPLETE = 'Complete',
  CLOSED = 'Closed',
  EXPIRED = 'Expired',
}

export interface OrderData {
  OrderId: DeviceDataValue<string>;
  Status: DeviceDataValue<OrderStatusEnum>;
  OrderStatusDatetime: DeviceDataValue<string>;
  OrderType: DeviceDataValue<ReportTemplate>;
}

export interface OrderSubmissionData {
  OrderId?: string;
  Status: OrderStatusEnum;
  OrderStatusDatetime?: string;
  OrderType?: ReportTemplate;
}
