import { useEffect, useMemo, useState } from 'react';
import { Modal, Stack } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { EyesPosition, Session } from 'src/models/Session.model';

import useGetSessions from 'src/hooks/useGetSessions';

import getGradingResult from 'src/utils/getGradingResult';

import ProgressModalBody from './ProgressModalBody';
import ProgressModalFooter from './ProgressModalFooter';

type AnalyzingProgressModalProps = {
  leftSaved: boolean;
  rightSaved: boolean;

  handleUnsetSavedEyePosition: (position: EyesPosition) => void;

  isShowAnalyzingProgressModal: boolean;
  handleCloseAnalyzingProgressModal: () => void;
  session: Session;

  handleResetSession: () => void;

  handleSetLeftUrl: (leftUrl: string) => void;
  handleSetRightUrl: (rightUrl: string) => void;
};
export default function AnalyzingProgressModal({
  leftSaved,
  rightSaved,
  handleUnsetSavedEyePosition,
  isShowAnalyzingProgressModal,
  handleCloseAnalyzingProgressModal,
  session,
  handleResetSession,
}: AnalyzingProgressModalProps) {
  const location = useLocation<{
    isResume: boolean;
  }>();

  const totalOfImagingEyes = useMemo(
    () =>
      location.state?.isResume
        ? (session.leftImageFile ? 1 : 0) + (session.rightImageFile ? 1 : 0)
        : (leftSaved ? 1 : 0) + (rightSaved ? 1 : 0),
    [
      leftSaved,
      location.state?.isResume,
      rightSaved,
      session.leftImageFile,
      session.rightImageFile,
    ],
  );

  const { totalGradableEyes } = getGradingResult(session);

  const hasCompletedExam = totalOfImagingEyes === totalGradableEyes;

  const { getSessions, totalLeftImageAttempt, totalRightImageAttempt } =
    useGetSessions(session);

  const [isFindingRetakeSessions, setIsFindingRetakeSessions] = useState(false);

  const handleSetIsFindingRetakeSessions = (
    isFindingRetakeSessions: boolean,
  ) => {
    setIsFindingRetakeSessions(isFindingRetakeSessions);
  };

  useEffect(() => {
    getSessions(handleSetIsFindingRetakeSessions);
  }, [getSessions]);

  const shouldShowDilationRecommendation =
    (totalLeftImageAttempt >= 3 || totalRightImageAttempt >= 3) &&
    totalLeftImageAttempt < 6 &&
    totalRightImageAttempt < 6 &&
    !hasCompletedExam;

  const shouldShowReachMaxRecommendedAttempts =
    totalLeftImageAttempt >= 6 || totalRightImageAttempt >= 6;

  return (
    <>
      <Modal
        backdrop="static"
        show={isShowAnalyzingProgressModal}
        onHide={handleCloseAnalyzingProgressModal}
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Stack>
            <Modal.Title>Analyzing Fundus Images</Modal.Title>
            <p>
              Imaging Attempts: Left Eye {totalLeftImageAttempt} | Right Eye{' '}
              {totalRightImageAttempt}
            </p>
          </Stack>
        </Modal.Header>
        <ProgressModalBody
          session={session}
          shouldShowDilationRecommendation={shouldShowDilationRecommendation}
          shouldShowReachMaxRecommendedAttempts={
            shouldShowReachMaxRecommendedAttempts
          }
        />
        <ProgressModalFooter
          handleUnsetSavedEyePosition={handleUnsetSavedEyePosition}
          session={session}
          leftSaved={leftSaved}
          rightSaved={rightSaved}
          totalOfImagingEyes={totalOfImagingEyes}
          hasCompletedExam={hasCompletedExam}
          shouldShowDilationRecommendation={shouldShowDilationRecommendation}
          shouldShowReachMaxRecommendedAttempts={
            shouldShowReachMaxRecommendedAttempts
          }
          handleCloseAnalyzingProgressModal={handleCloseAnalyzingProgressModal}
          handleResetSession={handleResetSession}
          totalLeftImageAttempt={totalLeftImageAttempt}
          totalRightImageAttempt={totalRightImageAttempt}
          isFindingRetakeSessions={isFindingRetakeSessions}
        />
      </Modal>
    </>
  );
}
