import { useCallback, useState } from 'react';

import { Api } from 'src/models/Api.model';
import { EntityCriteriaOperatorEnum } from 'src/models/GalenData/EntityCriteria.model';
import { GalenDataUser } from 'src/models/GalenData/User.model';
import { UserRoleEnum } from 'src/models/GalenData/UserRole.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

export default function useGetUsers() {
  const [users, setUsers] = useState<GalenDataUser[]>([]);

  const fetchUsersByRole = async (
    role: UserRoleEnum,
  ): Promise<GalenDataUser[]> => {
    const response = await Api.remote._get_users_advanced(
      [
        {
          key: 'role',
          operator: EntityCriteriaOperatorEnum.NotEqual,
          value: role,
        },
      ],
      {
        sortBy: 'createdOn',
        sortOrder: 'DESC',
      },
    );

    return response.data.content ?? [];
  };

  const [isGettingUsers, setIsGettingUsers] = useState(false);

  const handleGetUsers = useCallback(async () => {
    try {
      setIsGettingUsers(true);
      const patientUserList = await fetchUsersByRole(UserRoleEnum.Patient);

      setUsers(patientUserList);
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);
      Api.alertBox('Error', `Failed to load users. ${errorMessage}`);
    } finally {
      setIsGettingUsers(false);
    }
  }, []);

  return { users, handleGetUsers, isGettingUsers };
}
