import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Tab,
  Tabs,
} from 'react-bootstrap';

import { faFileImage } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { Application } from 'src/models/Application.model';
import { Camera } from 'src/models/Camera.model';
import { CameraData } from 'src/models/CameraData.model';
import { CameraMode } from 'src/models/CameraMode.model';
import { initSettings } from 'src/models/InitialSettings.model';
import { Language } from 'src/models/Language.model';
import { Product } from 'src/models/Product.model';
import { updateSettings } from 'src/models/Settings.model';

import CameraSettings from './CameraSettingsTab';

interface ISettingsTabProps {
  app: Application;
  cameraMode: CameraMode;
  setCameraMode: Dispatch<SetStateAction<CameraMode>>;
  cameraData: CameraData | undefined;
  setCameraData: Dispatch<SetStateAction<CameraData | undefined>>;
  ipList: string[];
  setIpList: Dispatch<SetStateAction<string[]>>;
}

const SettingsTab = ({
  app,
  cameraMode,
  setCameraMode,
  cameraData,
  setCameraData,
  ipList,
  setIpList,
}: ISettingsTabProps) => {
  const {
    setLogo,
    setSettings,
    getSettings,
    logo,
    language,
    userType,
    username,
    product,
    camera,
  } = useBoundStore();

  const [key, setKey] = useState<string>('Camera');

  useEffect(() => {
    setKey(camera === Camera.LARGE ? 'Application' : 'Camera');
  }, [camera]);

  const [formValues, setFormValues] = useState(initSettings);

  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
  ) => {
    const { id, value } = ev.target;
    const checked = (ev.target as any).checked;
    const newFormValues = updateSettings(formValues, id, value, checked);

    setFormValues(newFormValues);
    setSettings(newFormValues);
    Api.remote.save_settings(username, newFormValues);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadLogo = () => {
    fileInputRef.current?.click();
  };

  const handleSubmitLogo = async () => {
    const newFormValues = Object.assign({}, formValues);
    // upload files
    const _files = fileInputRef.current?.files;
    if (_files == null) {
      return;
    }

    if (_files.length !== 1) {
      return;
    }

    const file = _files[0] as File;

    const allowedTypes = ['image/png'];

    if (!allowedTypes.includes(file.type)) {
      Api.alertBox(
        'Error',
        'Unsupported file type. Only PNG files are allowed.',
      );
      return;
    }

    const logoId = await Api.remote.upload_device_data_media(
      process.env.REACT_APP_LOGO_DEVICE_DATA_MODEL_ID ?? '',
      process.env.REACT_APP_LOGO_DEVICE_PROPERTY_SET_ID ?? '',
      process.env.REACT_APP_LOGO_DEVICE_PROPERTY_CODE ?? '',
      file,
    );

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result || '';
      setLogo({ logo: base64data as string });
    };
    reader.readAsDataURL(file);

    newFormValues['logoFile'] = logoId.data;

    setSettings(newFormValues);

    Api.remote.save_settings(username, newFormValues);
  };

  const isAssurePlus = product === Product.ASSUREPLUS;
  const isClient = Api.isClient();

  const isAdmin = userType !== 'PracticeUser';

  // Waits for settings to be fetched from Galen Data before displaying the page.
  // Langauge is used because in settings it can never be falsy. Thus, if settings
  // is not yet loaded, it will be false and true when loaded.
  useEffect(() => {
    const tempSettings = getSettings();
    if (language) {
      setFormValues(tempSettings);
      setIsLoading(false);
    }
  }, [getSettings, language]);

  return (
    <Container fluid className="main-content optain-bg">
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spinner className="mx-5 my-5" variant="optain" />
        </div>
      ) : (
        <div>
          <div className="w-100" style={{ height: 490 }}>
            <h3>{key} Settings</h3>

            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k as string)}
              className="mt-3"
            >
              {camera === Camera.SMALL && (
                <Tab eventKey="Camera" title="Camera">
                  <CameraSettings
                    cameraMode={cameraMode}
                    setCameraMode={setCameraMode}
                    setCameraData={setCameraData}
                    cameraData={cameraData}
                    ipList={ipList}
                    setIpList={setIpList}
                  />
                </Tab>
              )}

              <Tab eventKey="Application" title="Application" className="pt-4">
                <Row>
                  <Col md={6}>
                    <Card className="data-container mt-2 p-0">
                      <Card.Body>
                        {isAssurePlus && (
                          <>
                            <Form.Group>
                              <Form.Label>
                                <strong>Language</strong>
                              </Form.Label>
                              <Form.Select
                                id="language"
                                className="form-control"
                                value={formValues.language}
                                onChange={handleChange}
                                required
                                disabled={!isAdmin}
                              >
                                <option value="">Select...</option>

                                {Object.values(Language).map((lang) => (
                                  <option key={lang}>{lang}</option>
                                ))}
                              </Form.Select>
                              <small className="form-text text-info">
                                Select the default language of the reports.
                              </small>
                            </Form.Group>

                            {isClient && (
                              <Form.Group className="mt-4 pt-4 border-top">
                                <Form.Label>
                                  <strong>Image Folder</strong>
                                </Form.Label>
                                <Form.Control
                                  id="folder"
                                  type="text"
                                  placeholder="Image Folder"
                                  value={formValues.folder}
                                  onChange={handleChange}
                                  required
                                />
                                <small className="form-text text-info">
                                  Enter the folder name on the local disk for
                                  new fundus images.
                                </small>
                              </Form.Group>
                            )}

                            {/* disable billing code for now */}
                            <Form.Group className="mt-4 pt-4 border-top d-none">
                              <Form.Check
                                type="switch"
                                id="billingCode"
                                label="Billing Code"
                                checked={formValues.billingCode}
                                onChange={handleChange}
                                disabled={!isAdmin}
                              />

                              <small className="form-text text-info">
                                Enable billing code entry for sessions.
                              </small>
                            </Form.Group>
                          </>
                        )}

                        <Form.Group
                          className={isAssurePlus ? 'mt-4 pt-4 border-top' : ''}
                        >
                          <Form.Label>
                            <strong>Watermark Text</strong>
                          </Form.Label>
                          <Form.Control
                            id="watermark"
                            type="text"
                            placeholder="Enter Watermark Text"
                            value={formValues.watermark}
                            onChange={handleChange}
                            disabled={!isAdmin}
                          />
                          <small className="form-text text-info">
                            Updates watermark on reports
                          </small>
                        </Form.Group>
                      </Card.Body>
                    </Card>
                  </Col>

                  {isAssurePlus && (
                    <>
                      <Col md={6}>
                        <Card className="data-container mt-2 p-0">
                          <Card.Body>
                            <Form.Group>
                              <Form.Label>
                                <strong>Report Fields</strong>
                              </Form.Label>

                              <Form.Check
                                type="switch"
                                id="reportGender"
                                label="Patient Gender"
                                checked={formValues.reportGender}
                                onChange={handleChange}
                                disabled={!isAdmin}
                              />
                              <Form.Check
                                type="switch"
                                id="reportHeatmap"
                                label="Heatmap"
                                checked={formValues.reportHeatmap}
                                onChange={handleChange}
                                disabled={!isAdmin}
                              />
                              <Form.Check
                                type="switch"
                                id="reportLogo"
                                label="Company Logo"
                                checked={formValues.reportLogo}
                                onChange={handleChange}
                                disabled={!isAdmin}
                              />
                              <small className="form-text text-info">
                                Select the fields you would like to include on
                                the report.
                              </small>
                            </Form.Group>

                            <Form.Group className="mt-4 pt-4 border-top">
                              <Form.Label>
                                <strong>Company Logo</strong>
                              </Form.Label>
                              <div className="logo-outer">
                                {logo ? (
                                  <img
                                    className="w-100"
                                    src={logo || ''}
                                    alt="logo"
                                  />
                                ) : (
                                  <div className="logo-inner">
                                    <img
                                      src="/img/company-logo.png"
                                      alt="logo"
                                    />
                                  </div>
                                )}

                                <Button
                                  variant="dark mt-4"
                                  onClick={handleUploadLogo}
                                  disabled={!formValues.reportLogo && !isAdmin}
                                >
                                  <FontAwesomeIcon
                                    icon={faFileImage}
                                    className="me-2"
                                  ></FontAwesomeIcon>
                                  Upload Logo
                                </Button>
                                <input
                                  id="file-input"
                                  type="file"
                                  accept="image/*"
                                  className="d-none"
                                  ref={fileInputRef}
                                  onChange={handleSubmitLogo}
                                />
                              </div>
                            </Form.Group>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  )}
                </Row>
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </Container>
  );
};

export default SettingsTab;
