import { Redirect, Route, RouteProps } from 'react-router-dom';

import { Location } from 'history';

import Header from './components/custom_components/Header/Header';
import Sidebar from './components/Sidebar';
import { Application } from './models/Application.model';
import { UserRoleEnum } from './models/GalenData/UserRole.model';
import { UserPermission } from './models/Settings.model';
import useBoundStore from './store/useBoundStore';

const getRedirectPath = (
  location: Location,
  authToken: string,
  userType: UserRoleEnum | undefined,
  userPermission: UserPermission,
) => {
  if (!authToken) {
    return { pathname: '/login', state: { from: location } };
  }
  if (
    userType === UserRoleEnum.PracticeUser &&
    location.pathname === '/subuser'
  ) {
    return { pathname: '/', state: { from: location } };
  }
  if (location.pathname === '/results' && !userPermission.list) {
    return { pathname: '/', state: { from: location } };
  }
  if (location.pathname === '/session-form' && !userPermission.create) {
    return { pathname: '/settings', state: { from: location } };
  }
  return null;
};

function PrivateRoute({
  children,
  app,
  ...rest
}: { children: React.ReactNode; app: Application } & RouteProps) {
  const { authToken, userType, userPermission } = useBoundStore();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        const redirectPath = getRedirectPath(
          location,
          authToken,
          userType,
          userPermission,
        );
        if (redirectPath) {
          return <Redirect to={redirectPath} />;
        }
        return (
          <>
            <Sidebar app={app} show />
            <div id="page-content-wrapper">
              <Header app={app} show />
              {children}
            </div>
          </>
        );
      }}
    />
  );
}

export { PrivateRoute };
