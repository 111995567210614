import React, { Dispatch, SetStateAction, useState } from 'react';
import { Badge, Button, ButtonGroup, Stack } from 'react-bootstrap';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { Camera } from 'src/models/Camera.model';
import { CameraData } from 'src/models/CameraData.model';
import { CameraMode } from 'src/models/CameraMode.model';
import { CriteriaGroupGroupElementsInnerOperatorEnum } from 'src/models/GalenData/CriteriaGroupGroupElementsInner.model';
import {
  CompletedReasonEnum,
  pendingStatusText,
  Session,
  SessionStatusEnum,
} from 'src/models/Session.model';
import { Settings } from 'src/models/Settings.model';

import getGradingResult from 'src/utils/getGradingResult';
import getSessionsImageFiles from 'src/utils/getSessionsImageFiles';
import handleHttpRequestError from 'src/utils/handleHttpRequestError';

import ModalForActions from './ModalForActions';

type SessionTableRowProps = {
  cameraMode: CameraMode;

  cameraData: CameraData | undefined;

  session: Session;

  settings: Settings;

  setSession: Dispatch<SetStateAction<Session>>;

  showUsername: boolean;
};

export default function SessionTableRow({
  cameraMode,
  cameraData,
  session,
  settings,
  setSession,
  showUsername,
}: SessionTableRowProps) {
  const [showViewer, setShowViewer] = useState<boolean>(false);

  const handleSetShowViewer = (showViewer: boolean) => {
    setShowViewer(showViewer);
  };

  const [currentSession, setCurrentSession] = useState<Session>();

  const handleSetCurrentSession = (currentSession: Session) => {
    setCurrentSession(currentSession);
  };

  const { userPermission, camera } = useBoundStore();

  const handleViewReport = async (session: Session) => {
    try {
      const allowed = userPermission.read;

      if (!allowed) {
        Api.alertBox(
          'Error',
          'Failed to view the report. User permission denied.',
        );
        return;
      }

      if (!session.orderId) {
        throw new Error('Order id not found in the exam.');
      }

      const orders = await Api.remote.get_exam_order({
        groupElements: [
          {
            key: 'OrderId',
            operator: CriteriaGroupGroupElementsInnerOperatorEnum.Equal,
            value: session.orderId,
          },
        ],
      });

      if (!orders.data.content) {
        throw new Error('Order not found.');
      }

      handleSetCurrentSession({
        ...session,
        template: orders.data.content[0].data.OrderType.value,
      });
      handleSetShowViewer(true);
    } catch (error) {
      const errorMessage = handleHttpRequestError(error);

      Api.alertBox('Error', errorMessage);
    }
  };

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleSetIsShowConfirmModal = (isShowConfirmModal: boolean) => {
    setIsShowConfirmModal(isShowConfirmModal);
  };

  const [
    shouldShowWarnNoConnectedCameraModal,
    setShouldShowWarnNoConnectedCameraModal,
  ] = useState(false);

  const handleSetShouldShowWarnNoConnectedCameraModal = (
    shouldShowWarnNoConnectedCameraModal: boolean,
  ) => {
    setShouldShowWarnNoConnectedCameraModal(
      shouldShowWarnNoConnectedCameraModal,
    );
  };

  const { leftImageFileContainer, rightImageFileContainer } =
    getSessionsImageFiles([session, ...(session.retakeSessions ?? [])]);

  const { isLeftEyeGradable, isRightEyeGradable } = getGradingResult(session);

  return (
    <>
      <tr key={`${session.id}-${session.createdAt.valueOf()}`}>
        {showUsername && <td>{session.username}</td>}
        <td>
          <p>
            <span>
              <b>{session.date}</b>
            </span>
            <br />
            <span>{session.time}</span>
          </p>
        </td>
        <td>{session.id}</td>
        <td>
          {session.firstName} {session.lastName}
        </td>
        <td>{session.dob}</td>
        <td>
          {session.graded ? (
            <Stack gap={2}>
              <Badge pill bg={isLeftEyeGradable ? 'success' : 'secondary'}>
                Left Eye (
                {leftImageFileContainer.length > 0
                  ? leftImageFileContainer.length
                  : 'N/A'}
                )
              </Badge>
              <Badge pill bg={isRightEyeGradable ? 'success' : 'secondary'}>
                Right Eye (
                {rightImageFileContainer.length > 0
                  ? rightImageFileContainer.length
                  : 'N/A'}
                )
              </Badge>
            </Stack>
          ) : (
            <Badge pill bg="secondary-200" className="text-black">
              {pendingStatusText(session)}
            </Badge>
          )}
        </td>
        <td>
          {!session.graded && (
            <Badge pill bg="secondary-200" className="text-black">
              {pendingStatusText(session)}
            </Badge>
          )}

          {session.graded && !session.completedReason && (
            <Badge pill bg="secondary">
              {SessionStatusEnum.IN_PROGRESS}
            </Badge>
          )}

          {(session.completedReason === CompletedReasonEnum.COMPLETED_EXAM ||
            session.completedReason === CompletedReasonEnum.AUTO_COMPLETE) &&
            (session.leftResults?.length ? isLeftEyeGradable : true) &&
            (session.rightResults?.length ? isRightEyeGradable : true) && (
              <Badge pill bg="success">
                {SessionStatusEnum.EXAM_SUCCESSFUL}
              </Badge>
            )}

          {(session.completedReason === CompletedReasonEnum.END_SESSION ||
            ((session.completedReason === CompletedReasonEnum.COMPLETED_EXAM ||
              session.completedReason === CompletedReasonEnum.AUTO_COMPLETE) &&
              ((!!session.leftResults?.length && !isLeftEyeGradable) ||
                (!!session.rightResults?.length && !isRightEyeGradable)))) && (
            <Badge pill bg="secondary-200" className="text-black">
              {SessionStatusEnum.EXAM_ENDED}
            </Badge>
          )}
        </td>
        <td>
          <ButtonGroup aria-label="Review report and resume">
            <Button
              variant="primary"
              className="border px-5 py-2"
              onClick={() => handleViewReport(session)}
              disabled={
                !session.graded || (session.graded && !session.completedReason)
              }
            >
              View report
            </Button>
            <Button
              variant="primary"
              className="border px-4 py-2"
              disabled={!(session.graded && !session.completedReason)}
              onClick={() => {
                if (
                  camera === Camera.SMALL &&
                  cameraMode === CameraMode.API &&
                  !cameraData
                ) {
                  handleSetShouldShowWarnNoConnectedCameraModal(true);
                  return;
                }
                handleSetIsShowConfirmModal(true);
              }}
            >
              Resume
            </Button>
          </ButtonGroup>
        </td>
      </tr>
      <ModalForActions
        session={session}
        showViewer={showViewer}
        currentSession={currentSession}
        handleSetShowViewer={handleSetShowViewer}
        settings={settings}
        shouldShowWarnNoConnectedCameraModal={
          shouldShowWarnNoConnectedCameraModal
        }
        setSession={setSession}
        isShowConfirmModal={isShowConfirmModal}
        handleSetIsShowConfirmModal={handleSetIsShowConfirmModal}
      />
    </>
  );
}
