import { useState } from 'react';
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from 'react-bootstrap';

import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useBoundStore from 'src/store/useBoundStore';

import BroadcastMessage from 'src/components/BroadcastMessage';
import ChangePwdModal from 'src/components/custom_components/ChangePwdModal';

import { Api } from 'src/models/Api.model';
import { Application } from 'src/models/Application.model';

import useCheckNavigation from 'src/hooks/useCheckNavigation';

export type ActFormValueTypes = {
  machineId: string;
  activationKey: string;
  validDate: string;
  credits: number;
};

interface IHeaderProps {
  app: Application;
  show: boolean;
}

const Header = ({ app, show }: IHeaderProps) => {
  // change password dialog
  const [showChangePassword, setShowChangePassword] = useState(false);

  const handlePwdOpen = () => setShowChangePassword(true);
  const handlePwdClose = () => setShowChangePassword(false);

  const { fullName, username } = useBoundStore();
  const { checkNavigation } = useCheckNavigation();

  const offlineUserDisplay = (
    <>
      <span className="me-3 text-black">User:</span>
      <FontAwesomeIcon
        icon={faUser}
        className="me-2 text-black"
      ></FontAwesomeIcon>
      <span className="text-black">{fullName}</span>
    </>
  );

  const { logOut, getSettings } = useBoundStore();
  const settings = getSettings();

  const handleLogout = () => {
    Api.confirmBox('Information', 'Are you sure to logout?', (ok: boolean) => {
      if (!ok) {
        return;
      }

      logOut();
    });
  };

  return (
    <>
      {show && (
        <>
          <Navbar key="lg" bg="white border-bottom" expand="lg" sticky="top">
            <Container fluid>
              <Navbar.Brand href="#" className="d-md-block d-lg-none ms-4">
                <img
                  src={`/icons/logo-b/${app.product}.png`}
                  className="sidebar-icon"
                  alt="logo"
                />
                <span className="title">{app.header}</span>
              </Navbar.Brand>

              <BroadcastMessage settings={settings} />

              <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                className="me-4"
              />
              <Navbar.Offcanvas
                id="basic-navbar-nav"
                aria-labelledby="basic-navbar-nav-expand"
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id="basic-navbar-nav-expand">
                    <img
                      src={`/icons/logo-b/${app.product}.png`}
                      className="sidebar-icon"
                      alt="logo"
                    />
                    <span className="title">{app.header}</span>
                  </Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 ps-3">
                    <Nav.Link
                      href="#/"
                      className="d-md-block d-lg-none btn btn-outline-light text-start text-black"
                      onClick={checkNavigation}
                    >
                      New Exam
                    </Nav.Link>

                    <Nav.Link
                      href="#/results"
                      className="d-md-block d-lg-none btn btn-outline-light text-start text-black"
                      onClick={checkNavigation}
                    >
                      Results
                    </Nav.Link>

                    <Nav.Link
                      href="#/settings"
                      className="d-md-block d-lg-none btn btn-outline-light text-start text-black"
                      onClick={checkNavigation}
                    >
                      Settings
                    </Nav.Link>

                    <Nav.Link
                      href="#/about"
                      className="d-md-block btn btn-outline-light text-start text-black"
                      onClick={checkNavigation}
                    >
                      About
                    </Nav.Link>

                    <NavDropdown
                      align="end"
                      title={offlineUserDisplay}
                      id="basic-nav-dropdown"
                      className="btn btn-outline-light text-start ms-2"
                    >
                      <NavDropdown.Item href="#" onClick={handlePwdOpen}>
                        Change Password
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item href="#" onClick={handleLogout}>
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
          <ChangePwdModal
            isShowChangePwdModal={showChangePassword}
            userName={username}
            handleCloseChangePwdModal={handlePwdClose}
            canBeCanceled
          />
        </>
      )}
    </>
  );
};

export default Header;
