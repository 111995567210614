enum Disease {
  DR = 'dr',
  VTDR = 'vtDR', // referable DR = vt (vision-threatening) DR
  MTMDR = 'mtmDR', // mtm (more-than-mild) DR
  GLAUCOMA = 'glaucoma',
  AMD = 'amd',
  REFAMD = 'amdnp',
  FUNDUS = 'fundus',
  AGE = 'age',
  CVD_RISK = 'who_pred',
  CVD_RANK = 'who_rank',

  VESSEL_SEGMENTATION = 'vessel_seg',

  ATHEROSCLEROSIS = 'vascular_risk',

  COMMON = 'common_disease',
  COMMON_BORDERLINE = 'suspect_disease',

  UNCOMMON = 'uncommon_disease', // not used
}

const DiseaseName = (disease: Disease) => {
  switch (disease) {
    case Disease.GLAUCOMA: {
      return 'Glaucoma';
    }
    case Disease.DR: {
      return 'DR';
    }
    case Disease.VTDR: {
      return 'vtDR';
    }
    case Disease.MTMDR: {
      return 'mtmDR';
    }
    case Disease.AMD: {
      return 'AMD';
    }
    case Disease.REFAMD: {
      return 'Ref. AMD';
    }
    case Disease.AGE: {
      return 'Retinal Age';
    }
    case Disease.FUNDUS: {
      return 'Fundus';
    }
    case Disease.CVD_RISK: {
      return 'CVD Risk';
    }
    case Disease.CVD_RANK: {
      return 'CVD Health';
    }

    case Disease.ATHEROSCLEROSIS: {
      return 'Atherosclerosis';
    }

    case Disease.COMMON: {
      return 'Other';
    }

    case Disease.COMMON_BORDERLINE: {
      return 'Other (Borderline)';
    }

    case Disease.UNCOMMON: {
      return 'Uncommon';
    }

    default: {
      return `Unknown: ${disease}`;
    }
  }
};

const DiseaseFullName = (disease: Disease) => {
  switch (disease) {
    case Disease.GLAUCOMA: {
      return 'Glaucoma';
    }
    case Disease.DR: {
      return 'Diabetic Retinopathy';
    }
    case Disease.VTDR: {
      return 'Vision-threatening Diabetic Retinopathy';
    }
    case Disease.MTMDR: {
      return 'More-than-mild Diabetic Retinopathy';
    }
    case Disease.AMD: {
      return 'Age-related Macular Degeneration';
    }
    case Disease.REFAMD: {
      return 'Referable AMD';
    }
    case Disease.AGE: {
      return 'Retinal Age';
    }
    case Disease.FUNDUS: {
      return 'Fundus';
    }
    case Disease.CVD_RISK: {
      return 'Absolute Cardiovascular Risk';
    }
    case Disease.CVD_RANK: {
      return 'Cardiovascular Health Status';
    }

    case Disease.ATHEROSCLEROSIS: {
      return 'Retinal atherosclerosis';
    }

    case Disease.COMMON: {
      return 'Other retinal diseases';
    }

    case Disease.COMMON_BORDERLINE: {
      return 'Suspect retinal diseases';
    }

    case Disease.UNCOMMON: {
      return 'Other uncommon retinal diseases';
    }

    default: {
      return `Unknown: ${disease}`;
    }
  }
};

export { Disease, DiseaseFullName, DiseaseName };
