import { AxiosResponse, isAxiosError } from 'axios';
import { forEach } from 'lodash';
import moment from 'moment';
import qs from 'qs';
import httpRequest from 'src/services/httpRequest';
import Url from 'url-parse';

import { SortOrder } from 'src/models/GalenData/SortOrder.model';
import { initSettings } from 'src/models/InitialSettings.model';
import { CompletedReasonEnum, Session } from 'src/models/Session.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

import { Disease } from './Disease.model';
import { AddUserRequestBody } from './GalenData/AddUserRequestBody.model';
import { LOCALSTORAGE_KEY } from './GalenData/Auth.model';
import {
  CriteriaGroupGroupElementsInner,
  CriteriaGroupGroupElementsInnerOperatorEnum,
} from './GalenData/CriteriaGroupGroupElementsInner.model';
import { ExamSubmissionData } from './GalenData/CustomDeviceDataModel.model';
import {
  CustomPracticeProfileFieldEnum,
  CustomUserProfileFieldEnum,
} from './GalenData/CustomField.model';
import { CustomFieldData } from './GalenData/CustomFieldData.model';
import { DeviceDataAggregateRequestBody } from './GalenData/DeviceDataAggregateRequestBody.model';
import { DeviceDataGetFilter } from './GalenData/DeviceDataGetFilter.model';
import { DeviceDataGetRequestBody } from './GalenData/DeviceDataGetRequestBody.model';
import { DeviceDataGetRequestBodyCriteriaGroup } from './GalenData/DeviceDataGetRequestBodyCriteriaGroup.model';
import { DeviceDataMultiplePropertiesAggregateRequestBody } from './GalenData/DeviceDataMultiplePropertiesAggregateRequestBody.model';
import { DeviceDataSaveRequestBody } from './GalenData/DeviceDataSaveRequestBody.model';
import {
  BackendStatus,
  DeviceDataView,
  ExamData,
  GradingDetail,
  GradingResults,
} from './GalenData/DeviceDataView.model';
import { getByFiltersUsingGET9 } from './GalenData/getByFiltersUsingGET9.model';
import { getByOrgFilter } from './GalenData/getByOrgFilter.model';
import submitSession from './GalenData/helpers/submitSession';
import {
  LoginUsingPOST,
  SendMFACodePost,
} from './GalenData/LoginUsingPOST.model';
import {
  OrderData,
  OrderStatusEnum,
  OrderSubmissionData,
} from './GalenData/Order.model';
import { OwnerFilter } from './GalenData/OwnerFilter.model';
import { PageOfCustomField } from './GalenData/PageOfCustomField.model';
import {
  AboutData,
  PageOfDeviceData,
} from './GalenData/PageOfDeviceData.model';
import { PageOfDeviceDataModel } from './GalenData/PageOfDeviceDataModel.model';
import { PageOfDeviceDataView } from './GalenData/PageOfDeviceDataView.model';
import { PageOfPractice } from './GalenData/PageOfPractice.model';
import { PageOfUser } from './GalenData/PageOfUser.model';
import { Practice } from './GalenData/Practice.model';
import {
  ApiUrlsEnum,
  AxiosResponseWithRefreshToken,
  ContentTypeEnum,
  IGetUserAdvancedParams,
  IGetUsersAdvancedRequestBody,
} from './GalenData/Request.model';
import { UpdatePasswordRequestBody } from './GalenData/UpdatePasswordRequestBody.model';
import { GalenDataUser, UserGenderEnum } from './GalenData/User.model';
import { UserManagementApiSendPasswordResetCodeUsingPOSTRequest } from './GalenData/UserManagementApiSendPasswordResetCodeUsingPOSTRequest.model';
import { UserRequestBody } from './GalenData/UserRequestBody.model';
import { UserRoleEnum } from './GalenData/UserRole.model';
import { Gender } from './Gender.model';
import { Grading } from './Grading.model';
import { ModelEnum } from './Model.model';
import { PageOfDeviceDataAggregateResult } from './PageOfDeviceDataAggregateResult.model';
import { PageOfDeviceDataMultipleAggregate } from './PageOfDeviceDataMultipleAggregate.model';
import { ReportStatus } from './ReportStatus.model';
import { Result } from './Result.model';
import { SegmentationType } from './SegmentationType.model';
import { Settings, updateSettings, UserPermission } from './Settings.model';

// ---- remote function wrapper ----
type callbackFunction = (...params: any[]) => void;

interface DiseaseHeatmaps {
  [Disease.AMD]?: string;
  [Disease.DR]?: string;
  [Disease.GLAUCOMA]?: string;
}

const isClient = (): boolean => {
  return window._cdvElectronIpc !== undefined;
};

const isStandalone = (): boolean => {
  return (
    window.location.hostname === 'localhost' ||
    window.location.hostname === '127.0.0.1'
  );
};

const isBrowser = (): boolean => {
  return !isClient() && !isStandalone();
};

// ---- client function wrapper ----
const clientFunction = (func: string, ...args: any[]): any => {
  if (isClient()) {
    if (window._cdvElectronIpc[func] === undefined) {
      console.log('Error: client api not found', func);
    } else {
      const ret = window._cdvElectronIpc[func](...args);

      // return Promise
      return ret instanceof Promise ? ret : Promise.resolve(ret);
    }
  } else {
    console.log('Error: calling client api in non-client environment', func);
  }
};

export const transformExam = (
  exam: DeviceDataView<ExamData>,
  username?: string,
) => {
  const patient = exam.owner;

  const leftEyeResults = exam?.data?.LeftEyeResultJson?.value.results;
  const leftEyeHeatmaps = {
    [Disease.AMD]: exam?.data?.LeftEyeAmdHeatmap?.link,
    [Disease.DR]: exam?.data?.LeftEyeDrHeatmap?.link,
    [Disease.GLAUCOMA]: exam?.data?.LeftEyeGlaucomaHeatmap?.link,
  };

  const rightEyeResults = exam?.data?.RightEyeResultJson?.value.results;
  const rightEyeHeatmaps = {
    [Disease.AMD]: exam?.data?.RightEyeAmdHeatmap?.link,
    [Disease.DR]: exam?.data?.RightEyeDrHeatmap?.link,
    [Disease.GLAUCOMA]: exam?.data?.RightEyeGlaucomaHeatmap?.link,
  };

  const session: Session = {
    iid: exam.deviceDataId || '',
    username: username || '',

    id: patient.patientId || '',
    firstName: patient.firstName,
    lastName: patient.lastName,
    age: patient.dateOfBirth
      ? moment().diff(patient.dateOfBirth, 'years')
      : undefined,
    gender:
      patient.gender === UserGenderEnum.Female ? Gender.FEMALE : Gender.MALE,
    dob: patient.dateOfBirth,

    date: moment(exam?.data?.ReportDatetime?.value).format('YYYY-MM-DD'),
    time: moment(exam?.data?.ReportDatetime?.value).format('HH:mm:ss'),

    leftImageFile: exam?.data?.LeftImage?.link,
    rightImageFile: exam?.data?.RightImage?.link,

    graded:
      exam?.data?.Status?.value === ReportStatus.COMPLETE ||
      exam?.data?.Status?.value === ReportStatus.FAILED,

    leftResults:
      exam?.data.Status?.value === ReportStatus.COMPLETE
        ? resolveResult(leftEyeResults, leftEyeHeatmaps)
        : [],
    rightResults:
      exam?.data.Status?.value === ReportStatus.COMPLETE
        ? resolveResult(rightEyeResults, rightEyeHeatmaps)
        : [],

    leftSegmentations:
      exam?.data.Status?.value === ReportStatus.COMPLETE
        ? [
            {
              type: SegmentationType.GRID,
              filename: exam.data.LeftEyeVesselSeg?.link || '',
              createdAt: new Date(),
            },
          ]
        : [],
    rightSegmentations:
      exam?.data.Status?.value === ReportStatus.COMPLETE
        ? [
            {
              type: SegmentationType.GRID,
              filename: exam.data.RightEyeVesselSeg?.link || '',
              createdAt: new Date(),
            },
          ]
        : [],

    notes: undefined,
    appointment: undefined,

    createdAt: moment(exam?.data?.ExamSubmissionDatetime?.value).toDate(),

    code: undefined,

    leftEyeDilatedTime: exam.data.LeftEyeDilatedTime?.value,
    rightEyeDilatedTime: exam.data.RightEyeDilatedTime?.value,

    completedTime: exam.data.CompletedTime?.value,
    completedReason: exam.data.CompletedReason?.value,

    leftImageFileName: exam?.data?.LeftImage?.value,
    rightImageFileName: exam?.data?.RightImage?.value,
    orderId: exam?.data?.OrderId?.value,

    sessionId: exam?.data?.SessionId?.value,
  };

  return session;
};

export const getUserFromUserId = async (userId: string) => {
  try {
    const res = await httpRequest.get<
      GalenDataUser,
      AxiosResponse<GalenDataUser>,
      getByFiltersUsingGET9
    >(ApiUrlsEnum.USER + `/${userId}`);

    return res.data;
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response &&
      error.response.status === 404
    ) {
      return;
    }
    throw error;
  }
};

const diseaseMapping: Record<string, Disease> = {
  [ModelEnum.DR_US]: Disease.DR,
  [ModelEnum.DR_EU]: Disease.DR,
  [ModelEnum.DR_EU_SMALL]: Disease.DR,

  [ModelEnum.AMD_EU]: Disease.AMD,
  [ModelEnum.AMD_EU_SMALL]: Disease.AMD,

  [ModelEnum.GLAUCOMA_EU]: Disease.GLAUCOMA,
  [ModelEnum.GLAUCOMA_EU_SMALL]: Disease.GLAUCOMA,

  [ModelEnum.CVD_EU]: Disease.CVD_RISK,
  [ModelEnum.CVD_EU_SMALL]: Disease.CVD_RISK,

  [ModelEnum.VESSEL_SEGMENTATION]: Disease.VESSEL_SEGMENTATION,
};

// Define a mapping of grading results to Result configurations
const gradingResultMapping: Record<
  string,
  { grading: Grading; gradable: boolean; error?: string }
> = {
  [Grading.UNGRADEABLE]: { grading: Grading.EMPTY, gradable: false },
  [Grading.ERROR]: {
    grading: Grading.EMPTY,
    gradable: false,
    error: 'Error',
  },

  // TODO: FIND A BETTER WAY TO DO THIS
  // DR-US
  [Grading.NEGATIVE]: { grading: Grading.NEGATIVE, gradable: true },
  [Grading.MTMDR.toLowerCase()]: { grading: Grading.MTMDR, gradable: true },
  [Grading.VTDR.toLowerCase()]: { grading: Grading.VTDR, gradable: true },

  // Glaucoma
  [Grading.LOW_RISK]: { grading: Grading.LOW_RISK, gradable: true },
  [Grading.MODERATE_RISK]: { grading: Grading.MODERATE_RISK, gradable: true },
  [Grading.HIGH_RISK]: { grading: Grading.HIGH_RISK, gradable: true },

  // AMD
  [Grading.NEGATIVE_OR_EARLY]: {
    grading: Grading.NEGATIVE_OR_EARLY,
    gradable: true,
  },
  [Grading.INTERMEDIATE]: { grading: Grading.INTERMEDIATE, gradable: true },
  [Grading.LATE_DRY]: { grading: Grading.LATE_DRY, gradable: true },
  [Grading.LATE_WET]: { grading: Grading.LATE_WET, gradable: true },

  // DR-EU
  [Grading.NO_DR]: { grading: Grading.NO_DR, gradable: true },
  [Grading.MILD_NPDR]: { grading: Grading.MILD_NPDR, gradable: true },
  [Grading.MODERATE_NPDR]: { grading: Grading.MODERATE_NPDR, gradable: true },
  [Grading.SEVERE_NPDR]: { grading: Grading.SEVERE_NPDR, gradable: true },
  [Grading.PROLIFERATIVE_DR]: {
    grading: Grading.PROLIFERATIVE_DR,
    gradable: true,
  },
};

const resolveResult = (
  results: GradingResults,
  heatmaps: DiseaseHeatmaps,
): Result[] => {
  const resultList: Result[] = [];

  forEach(results, (value, key) => {
    const disease = diseaseMapping[key];
    const results = resolveDiseaseResult(disease, value, heatmaps);
    resultList.push(...results);
  });

  return resultList;
};

const resolveDiseaseResult = (
  disease: Disease,
  value: GradingDetail | undefined,
  heatmaps: DiseaseHeatmaps,
): Result[] => {
  if (!value) {
    return [];
  }

  switch (disease) {
    case Disease.CVD_RISK: {
      return resolveCvdResult(value);
    }

    case Disease.DR:
    case Disease.AMD:
    case Disease.GLAUCOMA: {
      return resolveEyeResult(disease, value, heatmaps);
    }

    default: {
      return [];
    }
  }
};

const resolveCvdResult = (value: GradingDetail): Result[] => {
  let grading: Record<string, number> = {};

  try {
    grading = JSON.parse(value.grading);
  } catch {
    grading = {};
  }

  return [Disease.CVD_RISK, Disease.CVD_RANK].map((disease) => {
    const exists = disease in grading;

    return {
      disease,
      error: exists ? undefined : value.grading,
      gradable: exists,
      grading: exists ? grading[disease] : 0,
      conf: exists ? value.conf : 0,
      heatmapFile: undefined,
    };
  });
};

const resolveEyeResult = (
  disease: Disease.AMD | Disease.DR | Disease.GLAUCOMA,
  value: GradingDetail,
  heatmaps: DiseaseHeatmaps,
): Result[] => {
  const result = gradingResultMapping[value.grading.toLowerCase()];

  if (!result) {
    return [];
  }

  const { grading, gradable, error } = result;
  return [
    {
      disease,
      error,
      gradable,
      grading,
      conf: value.conf,
      heatmapFile: heatmaps[disease],
    },
  ];
};

const convertExamToSession = async (exam: DeviceDataView<ExamData>) => {
  const user = await getUserFromUserId(exam.data?.PracticeUserId?.value);
  const username = user?.emailAddress;

  return await transformExam(exam, username);
};

const stringToBoolean = (value: any): boolean =>
  typeof value === 'string' ? value.toLowerCase() === 'true' : false;

// ---- api ----
const Api = {
  consts: {
    MB_OK: 0x0,
    MB_OKCXL: 0x01,
    MB_YESNOCXL: 0x03,
    MB_YESNO: 0x04,
    MB_HELP: 0x40_00,
    ICON_EXLAIM: 0x30,
    ICON_INFO: 0x40,
    ICON_STOP: 0x10,

    FILES_PER_PAGE: 12,
    SESSIONS_PER_PAGE: 10,
  },

  alertBox(title: string, message: string, callback?: callbackFunction) {
    window.bootbox.alert({ title, message, callback });
  },

  alertBoxTimeout(
    title: string,
    message: string,
    timeoutInSeconds: number,
    callback?: callbackFunction,
  ) {
    const dialog = window.bootbox.alert({ title, message, callback });
    const btn = dialog.find('button');
    let count = 0;

    const check = () => {
      if (!dialog.is(':visible')) {
        return;
      }

      btn.text(`OK (${timeoutInSeconds - count})`);

      if (count >= timeoutInSeconds) {
        dialog.modal('hide');
        if (callback !== undefined) {
          callback();
        }
      } else {
        count++;
        setTimeout(check, 1000);
      }
    };

    check();
  },

  confirmBox(title: string, message: string, callback?: callbackFunction) {
    window.bootbox.confirm({ title, message, callback });
  },

  promptBox(
    title: string,
    message: string,
    defaultValue: string,
    callback?: callbackFunction,
  ) {
    window.bootbox.prompt({ title, message, value: defaultValue, callback });
  },

  formatException(err: string): string {
    if (err.endsWith(`')`)) {
      return (
        'Error: ' + err.slice(err.indexOf(`'`) + 1, -2).replace('Error: ', '')
      );
    } else if (err.endsWith(`")`)) {
      return (
        'Error: ' + err.slice(err.indexOf(`"`) + 1, -2).replace('Error: ', '')
      );
    } else {
      return err;
    }
  },

  // https://stackoverflow.com/questions/3895478/does-javascript-have-a-method-like-range-to-generate-a-range-within-the-supp
  range(start: number, stop: number, step: number = 1): number[] {
    const length = Math.ceil((stop - start) / step);
    return Array.from({ length }).map((_, index) => start + index * step);
  },

  backendPrefix() {
    return window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1' ||
      window.location.hostname.startsWith('192.168.')
      ? `http://${window.location.hostname}:36350`
      : '';
  },

  getLocalIpAddress(callback: any) {
    if (isClient()) {
      // client: get electron ip address
      clientFunction('getIpAddress')
        .then((ip: string) => {
          console.log('ip address', ip);
          callback(null, ip);
        })
        .catch((err: Error) => {
          callback(err);
        });
    } else {
      // otherwise: get backend ip address
      Api.remote
        .get_ip_address()
        .then((ip: string) => {
          console.log('ip address', ip);
          callback(null, ip);
        })
        .catch((err: Error) => {
          callback(err);
        });
    }
  },

  isClient,
  isStandalone,
  isBrowser,

  remote: {
    get_user_by_email: async (emailAddress: string) => {
      const res = await httpRequest.get<
        PageOfUser,
        AxiosResponse<PageOfUser>,
        getByFiltersUsingGET9
      >(ApiUrlsEnum.USER, {
        params: {
          emailAddress,
        },
      });

      return res.data.content?.[0];
    },
    login: async (username: string, password: string) => {
      return await httpRequest.post<
        GalenDataUser,
        AxiosResponseWithRefreshToken<GalenDataUser>,
        LoginUsingPOST
      >(
        ApiUrlsEnum.LOGIN,
        {
          emailAddress: username,
          password,
          // sendMFACode: false,
        },
        {
          headers: {
            'Content-Type': ContentTypeEnum.URL_ENCODED,
          },
        },
      );
    },
    verifyMFACode: async (code: string, xRequestToken: string) => {
      return await httpRequest.post<
        GalenDataUser,
        AxiosResponseWithRefreshToken<GalenDataUser>,
        SendMFACodePost
      >(ApiUrlsEnum.VERIFY_MFA_CODE, undefined, {
        params: {
          code,
        },
        headers: {
          'X-REQUEST-TOKEN': xRequestToken,
        },
      });
    },

    sendMFACode: async (xRequestToken: string) => {
      return await httpRequest.post<
        GalenDataUser,
        AxiosResponseWithRefreshToken<GalenDataUser>
      >(ApiUrlsEnum.SEND_MFA_CODE, undefined, {
        headers: {
          'X-REQUEST-TOKEN': xRequestToken,
        },
      });
    },

    logout: async (reloadPage: boolean) => {
      localStorage.removeItem(LOCALSTORAGE_KEY.AUTH_INFO);
      localStorage.removeItem(LOCALSTORAGE_KEY.CURRENT_USER);
      localStorage.removeItem(LOCALSTORAGE_KEY.LOGO);
      localStorage.removeItem(LOCALSTORAGE_KEY.SETTINGS);

      if (reloadPage) {
        // set timestamp
        const url = new Url(window.location.href, true);
        url.query.ts = Date.now().toString();

        // reload page
        const newUrl = url.toString();
        window.location.href = newUrl;
      }
      return true;
    },

    _getEntitySettings: async (entityId: string, settings: Settings) => {
      const res = await httpRequest.get<
        CustomFieldData[],
        AxiosResponse<CustomFieldData[]>,
        null
      >(ApiUrlsEnum.USER_CUSTOM_FIELD_DATA + `/${entityId}`);

      for (const field of res.data) {
        if (field.field.name === undefined) {
          continue;
        }

        if (field.field.name === 'userPermission') {
          for (const key of Object.keys(settings.userPermission)) {
            if (field.fieldData.includes(key)) {
              settings.userPermission[key as keyof UserPermission] = true;
            } else {
              settings.userPermission[key as keyof UserPermission] = false;
            }
          }

          continue;
        }

        settings = updateSettings(
          settings,
          field.field.name,
          field.fieldData ?? '',
          stringToBoolean(field.fieldData),
        );
      }

      return settings;
    },

    load_settings: async (
      username: string,
      userId: string,
      practiceId: string,
    ) => {
      try {
        let settings = Object.assign({}, initSettings);

        settings = await Api.remote._getEntitySettings(userId, settings);
        settings = await Api.remote._getEntitySettings(practiceId, settings);

        return settings;
      } catch {
        // settings not found or invalid, set default settings
        // practice users only have create and list permissions
        const settings: Settings = initSettings;

        await Api.remote.save_settings(username, settings);

        return settings;
      }
    },

    setting_custom_data: async (
      fieldArea: string,
      settings: Partial<Settings>,
    ) => {
      const res = await httpRequest.get<
        PageOfCustomField<
          CustomPracticeProfileFieldEnum | CustomUserProfileFieldEnum
        >,
        AxiosResponse<
          PageOfCustomField<
            CustomPracticeProfileFieldEnum | CustomUserProfileFieldEnum
          >
        >,
        null
      >(ApiUrlsEnum.USER_CUSTOM_FIELD, {
        params: { fieldArea },
      });

      const customData: CustomFieldData[] = [];
      if (res?.data?.content)
        for (const item of res?.data?.content) {
          // TODO: remove line once settings is refactored and locked
          if (!Object.keys(settings).includes(item.name)) {
            continue;
          }

          if (item.name === 'broadcast') {
            continue;
          }

          if (item.name === 'userPermission') {
            if (settings.userPermission === undefined) {
              continue;
            }
            const userPermission = settings.userPermission;

            const trueUserPermission = Object.keys(userPermission).filter(
              function (k) {
                return userPermission[k as keyof UserPermission];
              },
            );

            customData.push({
              field: item,
              fieldData: trueUserPermission,
            });
            continue;
          }

          customData.push({
            field: item,
            fieldData: String(settings[item.name as keyof Settings]),
          });
        }

      return customData;
    },

    save_settings: async (emailAddress: string, settings: Settings) => {
      const user = await Api.remote.get_user_by_email(emailAddress);

      const userData = await Api.remote.setting_custom_data(
        'UserProfile',
        settings,
      );
      httpRequest.put<UserRequestBody>(ApiUrlsEnum.USER_CUSTOM, {
        customData: userData,
        user,
      });

      const isAdmin = user?.currentRole?.role !== 'PracticeUser';
      if (isAdmin) {
        const practice = user?.currentRole?.practice;

        const practiceData = await Api.remote.setting_custom_data(
          'PracticeProfile',
          settings,
        );

        httpRequest.put<UserRequestBody>(ApiUrlsEnum.PRACTICE_CUSTOM, {
          customData: practiceData,
          practice,
        });
      }
    },

    select_folder: async (folder: string) => {
      console.error('select_folder: obsolete');
      return null;
    },

    test_database: async (config: any) => {
      console.error('test_database: obsolete');
      return false;
    },

    save_database: async (config: any) => {
      console.error('save_database: obsolete');
      return false;
    },

    delete_file: async (username: string, filename: string) => {
      console.error('delete_file: obsolete');
      return false;
    },

    clear_files: (username: string) => {
      console.error('clear_files: obsolete');
      return false;
    },

    lookup_patient: async (username: string, patientId: string) => {
      console.error('lookup_patient: obsolete');
      return {
        id: '',
        firstName: '',
        lastName: '',
        age: undefined,
        gender: undefined,
        dob: undefined,
      };
    },

    create_user: async (data: AddUserRequestBody) => {
      return await httpRequest.post<
        void,
        AxiosResponse<void>,
        AddUserRequestBody
      >(ApiUrlsEnum.USER, data);
    },

    update_user: async (data: GalenDataUser) => {
      return await httpRequest.put<void, AxiosResponse<void>, GalenDataUser>(
        ApiUrlsEnum.USER,
        data,
      );
    },

    upload_device_data_media: async (
      deviceDataModelId: string,
      devicePropertySetId: string,
      propertyCode: string,
      data: any,
      ownerId?: string,
      deviceDataId?: string,
    ) => {
      return await httpRequest.post(
        ApiUrlsEnum.DEVICE_DATA_MEDIA,
        {
          deviceDataId,
          deviceDataModelId,
          devicePropertySetId,
          propertyCode,
          ownerId,
          data,
        },
        {
          headers: {
            'Content-Type': ContentTypeEnum.MULTIPART,
          },
        },
      );
    },

    upload_media_file: async ({
      deviceDataId,
      propertyCode,
      imageUrl,
      imageFileName,
    }: {
      deviceDataId: string;
      propertyCode: string;
      imageUrl: string;
      imageFileName: string;
    }) => {
      const formData = new FormData();

      const imageBlob = await fetch(imageUrl).then((r) => r.blob());

      formData.append(
        'deviceDataModelId',
        process.env.REACT_APP_DEVICE_MODEL_ID,
      );
      formData.append('propertyCode', propertyCode);
      formData.append('data', imageBlob, imageFileName);

      await Api.remote._save_media_file(deviceDataId, formData);
    },

    submit_session: async (
      practiceId: string,
      practiceUserId: string,
      session: Session,
      models: ModelEnum[],
      sessionId: string,
    ) => {
      session.sessionId = sessionId;

      return await submitSession(practiceId, practiceUserId, session, models);
    },

    _save_device_data_record: async <T>(data: DeviceDataSaveRequestBody<T>) => {
      return await httpRequest.post(ApiUrlsEnum.DEVICE_DATA, data);
    },

    _save_media_file: async (deviceDataId: string, data: FormData) => {
      return await httpRequest.post(
        `${ApiUrlsEnum.DEVICE_DATA}/${deviceDataId}`,
        data,
        {
          headers: {
            'Content-Type': ContentTypeEnum.MULTIPART,
          },
        },
      );
    },

    _get_custom_field_data: async <T>(customFieldId: string) => {
      return await httpRequest.get<
        CustomFieldData<T>[],
        AxiosResponse<CustomFieldData[]>,
        string
      >(ApiUrlsEnum.USER_CUSTOM_FIELD_DATA + `/${customFieldId}`);
    },

    _get_all_device_data: async (params?: DeviceDataGetFilter) => {
      return await httpRequest.get<
        PageOfDeviceData<AboutData>,
        AxiosResponse<PageOfDeviceData<AboutData>>,
        DeviceDataGetFilter
      >(ApiUrlsEnum.DEVICE_DATA, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });
    },

    _get_device_data: async ({
      deviceDataModelId,
      deviceDataId,
    }: {
      deviceDataModelId: string;
      deviceDataId: string;
    }) => {
      return await httpRequest.get(
        `/data/devicedata/${deviceDataModelId}/${deviceDataId}`,
      );
    },

    _count_device_data: async (data: DeviceDataGetRequestBody) => {
      return await httpRequest.post<
        string,
        AxiosResponse<string>,
        DeviceDataGetRequestBody
      >(ApiUrlsEnum.COUNT_DEVICE_DATA, data);
    },

    get_device_data_advanced: async (data: DeviceDataGetRequestBody) => {
      return await httpRequest.post<
        PageOfDeviceDataModel,
        AxiosResponse<PageOfDeviceDataModel>,
        DeviceDataGetRequestBody
      >(ApiUrlsEnum.DEVICE_DATA_ADVANCED, data);
    },

    get_device_data_advanced_owner_custom_criteria: async <T>(
      data: DeviceDataGetRequestBodyCriteriaGroup,
      params?: Omit<
        DeviceDataGetFilter,
        'deviceDataModelId' | 'rangeStartDateTime' | 'rangeEndDateTime'
      >,
    ) => {
      return await httpRequest.post<
        PageOfDeviceDataView<T>,
        AxiosResponse<PageOfDeviceDataView<T>>,
        DeviceDataGetRequestBody
      >(ApiUrlsEnum.DEVICE_DATA_ADVANCED_OWNER_CUSTOM_CRITERIA, data, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });
    },

    get_exams: async ({
      ownerFilter,
      groupElements,
      params,
    }: {
      ownerFilter: OwnerFilter;
      groupElements: CriteriaGroupGroupElementsInner[];
      params?: Omit<
        DeviceDataGetFilter,
        'deviceDataModelId' | 'rangeStartDateTime' | 'rangeEndDateTime'
      >;
    }) => {
      try {
        return await Api.remote.get_device_data_advanced_owner_custom_criteria<ExamData>(
          {
            deviceDataModelId: process.env.REACT_APP_DEVICE_MODEL_ID,
            deviceCriteriaGroup: {
              groupElements,
            },
            ownerFilter,
          },
          params,
        );
      } catch (error) {
        if (
          isAxiosError(error) &&
          error.response &&
          error.response.status === 404
        ) {
          return {
            data: {
              content: undefined,
              totalElements: 0,
            },
          };
        }
        throw error;
      }
    },
    get_hidden_exams: async (
      users: string[],
      groupElements?: CriteriaGroupGroupElementsInner[],
    ) => {
      return Api.remote.get_exams({
        ownerFilter: {
          users,
        },
        groupElements: [
          {
            key: 'IsHidden',
            operator: CriteriaGroupGroupElementsInnerOperatorEnum.Equal,
            value: true,
          },
          ...(groupElements ?? []),
        ],
      });
    },
    get_not_hidden_exams: async (
      users: string[],
      params?: Omit<
        DeviceDataGetFilter,
        'deviceDataModelId' | 'rangeStartDateTime' | 'rangeEndDateTime'
      >,
    ) => {
      return Api.remote.get_exams({
        ownerFilter: {
          users,
        },
        groupElements: [
          {
            key: 'IsHidden',
            operator: CriteriaGroupGroupElementsInnerOperatorEnum.Equal,
            value: false,
          },
        ],
        params,
      });
    },

    update_exam_order: async (
      data: DeviceDataView<OrderData>,
      updateData: {
        status: OrderStatusEnum;
      },
    ) => {
      if (!data.deviceDataModelId || !data.deviceDataId) {
        throw new Error('Missing device data model id or device data id');
      }
      return await Api.remote._save_device_data_record<OrderSubmissionData>({
        deviceDataModelId: data.deviceDataModelId,
        devicePropertySetId:
          process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_ORDER,
        deviceDataId: data.deviceDataId,
        data: {
          Status: updateData.status,
        },
      });
    },

    get_exam_order: async ({
      groupElements,
      ownerFilter,
      params,
    }: {
      groupElements?: CriteriaGroupGroupElementsInner[];
      ownerFilter?: OwnerFilter;
      params?: Omit<
        DeviceDataGetFilter,
        'deviceDataModelId' | 'rangeStartDateTime' | 'rangeEndDateTime'
      >;
    }) => {
      try {
        return await Api.remote.get_device_data_advanced_owner_custom_criteria<OrderData>(
          {
            deviceDataModelId: process.env.REACT_APP_EXAM_ORDER_MODEL_ID,
            deviceCriteriaGroup: {
              groupElements,
            },
            ownerFilter,
          },
          params,
        );
      } catch (error) {
        if (
          isAxiosError(error) &&
          error.response &&
          error.response.status === 404
        ) {
          return {
            data: {
              content: undefined,
              totalElements: 0,
            },
          };
        }
        throw error;
      }
    },

    get_device_data_aggregate: async (data: DeviceDataAggregateRequestBody) => {
      return await httpRequest.post<
        PageOfDeviceDataAggregateResult,
        AxiosResponse<PageOfDeviceDataAggregateResult>,
        DeviceDataAggregateRequestBody
      >(`${ApiUrlsEnum.DEVICE_DATA_AGGREGATE}`, data);
    },

    get_device_data_aggregate_advanced: async (
      data: DeviceDataMultiplePropertiesAggregateRequestBody,
    ) => {
      return await httpRequest.post<
        PageOfDeviceDataMultipleAggregate,
        AxiosResponse<PageOfDeviceDataMultipleAggregate>,
        DeviceDataMultiplePropertiesAggregateRequestBody
      >(`${ApiUrlsEnum.DEVICE_DATA_AGGREGATE_ADVANCED}`, data);
    },

    get_practice: async (practiceId: string) => {
      return await httpRequest.get<Practice, AxiosResponse<Practice>, string>(
        ApiUrlsEnum.PRACTICE + `/${practiceId}`,
      );
    },

    search_practice: async (params?: getByOrgFilter) => {
      return await httpRequest.get<
        PageOfPractice,
        AxiosResponse<PageOfPractice>
      >(ApiUrlsEnum.PRACTICE, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });
    },

    search_supplier: async (params?: getByOrgFilter) => {
      return await httpRequest.get<
        PageOfPractice,
        AxiosResponse<PageOfPractice>
      >(ApiUrlsEnum.SUPPLIER, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      });
    },

    retake_session: async (
      practiceId: string,
      practiceUserId: string,
      session: Session,
      models: ModelEnum[],
      tempSessionId: string,
    ) => {
      const previousExamId = session.iid;

      // step 1: reuse patient and OrderId
      const previousExam = await Api.remote._get_device_data({
        deviceDataModelId: process.env.REACT_APP_DEVICE_MODEL_ID,
        deviceDataId: previousExamId,
      });

      const patientUserId = previousExam.data.ownerId;

      await Api.remote._save_device_data_record<{ IsHidden: boolean }>({
        deviceDataModelId: process.env.REACT_APP_DEVICE_MODEL_ID,
        devicePropertySetId:
          process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_SUBMISSION,
        deviceDataId: previousExamId,
        data: {
          IsHidden: true,
        },
      });

      // step 2: submit device data and get id
      const res = await Api.remote._save_device_data_record<ExamSubmissionData>(
        {
          deviceDataModelId: process.env.REACT_APP_DEVICE_MODEL_ID,
          devicePropertySetId:
            process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_SUBMISSION,
          ownerId: patientUserId,
          data: {
            SessionId: tempSessionId,
            PracticeUserId: practiceUserId,
            PracticeId: practiceId,
            ExamSubmissionMetadata: {
              models,
            },
            ExamSubmissionDatetime: new Date().toISOString(),
            ReportDatetime: new Date().toISOString(),
            RightEyeDilatedTime:
              previousExam.data.data?.RightEyeDilatedTime?.value,
            LeftEyeDilatedTime:
              previousExam.data.data?.LeftEyeDilatedTime?.value,
            IsHidden: false,
            OrderId: previousExam.data.data?.OrderId?.value,
          },
        },
      );

      const deviceDataId = res.data;

      // step 3: upload images
      if (session.leftImageFile !== undefined) {
        await Api.remote.upload_media_file({
          deviceDataId,
          propertyCode: 'LeftImage',
          imageUrl: session.leftImageFile,
          imageFileName: session.leftImageFileName,
        });
      }

      if (session.rightImageFile !== undefined) {
        await Api.remote.upload_media_file({
          deviceDataId,
          propertyCode: 'RightImage',
          imageUrl: session.rightImageFile,
          imageFileName: session.rightImageFileName,
        });
      }

      // step 4: set upload status
      await Api.remote._save_device_data_record<{
        UploadStatusComplete: boolean;
      }>({
        deviceDataModelId: process.env.REACT_APP_DEVICE_MODEL_ID,
        devicePropertySetId:
          process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_SUBMISSION,
        ownerId: patientUserId,
        deviceDataId,
        data: { UploadStatusComplete: true },
      });

      return deviceDataId;
    },

    load_sessions: async (
      page: number,
      numberPerPage: number,
      search: string,
      sortField: string,
      sortOrder: string,
    ) => {
      try {
        // load patients
        const patientParams: getByFiltersUsingGET9 = {};

        if (/^\d{4}-\d{2}-\d{2}$/.test(search)) {
          patientParams.dateOfBirth = search;
        } else if (/^\d+$/.test(search)) {
          patientParams.patientId = search;
        } else if (search !== '') {
          patientParams.nameLike = search;
        }

        patientParams.role = UserRoleEnum.Patient;

        const patients = await Api.remote._get_all_users(patientParams);
        const userIds: string[] = [];

        if (!patients.data.content) {
          return {
            sessions: [],
            total: 0,
          };
        }

        for (let i = 0; i < patients.data.content?.length; i++) {
          const userId = patients.data.content[i].userId;
          if (userId !== undefined) {
            userIds.push(userId);
          }
        }

        // load exams
        let sortBy = ['minValueProvidedOn'];

        if (sortField === 'id') {
          sortBy = ['patientId'];
        } else if (sortField === 'name') {
          sortBy = ['firstName', 'lastName'];
        }

        const exams = await Api.remote.get_not_hidden_exams(userIds, {
          pageNumber: page,
          pageSize: numberPerPage,
          sortBy,
          sortOrder: sortOrder === 'desc' ? 'DESC' : 'ASC',
        });

        // convert to sessions
        let sessions: Session[] = [];
        if (exams.data.content !== undefined) {
          const sessionPromises = exams.data.content.map((exam) =>
            convertExamToSession(exam),
          );
          sessions = await Promise.all(sessionPromises);
        }

        return {
          sessions,
          total: exams.data.totalElements || 0,
        };
      } catch {
        return {
          sessions: [],
          total: 0,
        };
      }
    },

    _load_patients: async (search: string) => {
      const patientParams: getByFiltersUsingGET9 = {};

      if (/^\d{4}-\d{2}-\d{2}$/.test(search)) {
        patientParams.dateOfBirth = search;
      } else if (/^\d+$/.test(search)) {
        patientParams.patientId = search;
      } else if (search !== '') {
        patientParams.nameLike = search;
      }

      patientParams.role = UserRoleEnum.Patient;

      return await Api.remote._get_all_users(patientParams);
    },
    _load_exams: async (
      page: number,
      numberPerPage: number,
      sortOrder: string,
      userIds: string[],
    ) => {
      // load exams
      const sortBy = ['data.ReportDatetime.value'];

      return await Api.remote.get_not_hidden_exams(userIds, {
        pageNumber: page,
        pageSize: numberPerPage,
        sortBy,
        sortOrder: sortOrder === 'DESC' ? 'DESC' : 'ASC',
      });
    },

    load_sessions_with_retake_sessions: async (
      page: number,
      numberPerPage: number,
      search: string,
      sortOrder: SortOrder,
    ) => {
      let sessions: Session[] = [];

      let userIds: string[] = [];

      if (search) {
        const patients = await Api.remote._load_patients(search);

        if (!patients.data.content) {
          return {
            sessions,
            total: 0,
          };
        }

        userIds = patients.data.content.map((patient) => patient.userId);
      }

      const exams = await Api.remote._load_exams(
        page,
        numberPerPage,
        sortOrder,
        userIds,
      );

      if (!exams || !exams.data.content) {
        return {
          sessions,
          total: 0,
        };
      }

      const updateUserIds = [];

      for (let i = 0; i < exams.data.content?.length; i++) {
        const userId = exams.data.content[i].ownerId;
        if (userId !== undefined) {
          updateUserIds.push(userId);
        }
      }

      const hiddenExams = await Api.remote.get_hidden_exams(updateUserIds);

      // convert to sessions
      const sessionHash: any = {};

      if (exams.data.content !== undefined) {
        const sessionPromises = exams.data.content.map(async (exam) => {
          const session = await convertExamToSession(exam);
          session.retakeSessions = [];
          sessionHash[exam.data.OrderId.value] = session; // Exam's OrderId -> session
          return session;
        });

        sessions = await Promise.all(sessionPromises);
      }

      // add hidden sessions
      if (hiddenExams !== undefined && hiddenExams.data.content) {
        const sessionPromises = hiddenExams.data.content.map(async (exam) => {
          const parentSession = sessionHash[exam.data.OrderId.value];
          if (parentSession) {
            const session = await convertExamToSession(exam);
            parentSession.retakeSessions.push(session);
          }
        });

        await Promise.all(sessionPromises);
      }

      return {
        sessions,
        total: exams.data.totalElements || 0,
      };
    },

    find_retake_sessions: async (iid: string) => {
      const parentExam = await httpRequest.get<
        DeviceDataView<ExamData>,
        AxiosResponse<DeviceDataView<ExamData>>,
        DeviceDataGetRequestBody
      >(
        `${ApiUrlsEnum.DEVICE_DATA_OWNER}/${process.env.REACT_APP_DEVICE_MODEL_ID}/${iid}`,
      );

      let sessions: Session[] = [];

      if (!parentExam.data.ownerId) {
        return sessions;
      }

      const exams = await Api.remote.get_hidden_exams(
        [parentExam.data.ownerId],
        [
          {
            key: 'OrderId',
            operator: CriteriaGroupGroupElementsInnerOperatorEnum.Equal,
            value: parentExam.data.data.OrderId.value,
          },
        ],
      );

      // convert to sessions
      if (exams.data.content !== undefined) {
        const sessionPromises = exams.data.content.map((exam) =>
          convertExamToSession(exam),
        );
        sessions = await Promise.all(sessionPromises);
      }

      const session = await convertExamToSession(parentExam.data);
      sessions.unshift(session);

      return sessions;
    },

    // load_session: async (username: string, iid: string) => {
    //   const exam = await httpRequest.get<
    //     DeviceDataView<ExamData>,
    //     AxiosResponse<DeviceDataView<ExamData>>,
    //     DeviceDataGetRequestBody
    //   >(
    //     `${ApiUrlsEnum.DEVICE_DATA_OWNER}/${process.env.REACT_APP_DEVICE_MODEL_ID}/${iid}`,
    //   );

    //   return await convertExamToSession(exam.data);
    // },
    //  TODO: refactor this function to not return null and fix test case 373 to pass
    load_session: async (iid: string) => {
      try {
        const exam = await httpRequest.get<
          DeviceDataView<ExamData>,
          AxiosResponse<DeviceDataView<ExamData>>,
          DeviceDataGetRequestBody
        >(
          `${ApiUrlsEnum.DEVICE_DATA_OWNER}/${process.env.REACT_APP_DEVICE_MODEL_ID}/${iid}`,
        );

        return await convertExamToSession(exam.data);
      } catch (error) {
        console.log('load session error', error);
        return null;
      }
    },

    save_session: async (
      iid: string,
      updateData: {
        completedReason: CompletedReasonEnum;
        completedTime: Date;
      },
    ) => {
      try {
        await Api.remote._save_device_data_record<{
          CompletedReason: CompletedReasonEnum;
          CompletedTime: Date;
        }>({
          deviceDataModelId: process.env.REACT_APP_DEVICE_MODEL_ID,
          devicePropertySetId:
            process.env.REACT_APP_DEVICE_PROPERTY_SET_ID_EXAM_SUBMISSION,
          deviceDataId: iid,
          data: {
            CompletedReason: updateData.completedReason,
            CompletedTime: updateData.completedTime,
          },
        });

        return true;
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);

        Api.alertBox('Error', `Failed to save the exam. ${errorMessage}`);
      }
      return false;
    },

    change_password: async (
      userId: string,
      currentPassword: string,
      newPassword: string,
    ) => {
      return await httpRequest.put<
        void,
        AxiosResponse<void>,
        UpdatePasswordRequestBody
      >(ApiUrlsEnum.CHANGE_PASSWORD, {
        userId,
        currentPassword,
        newPassword,
        newConfirmPassword: newPassword,
        forcePasswordReset: false,
      });
    },

    _get_all_users: async (params?: getByFiltersUsingGET9) => {
      try {
        return await httpRequest.get<
          PageOfUser,
          AxiosResponse<PageOfUser>,
          getByFiltersUsingGET9
        >(ApiUrlsEnum.USER, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        });
      } catch (error) {
        if (
          isAxiosError(error) &&
          error.response &&
          error.response.status === 404
        ) {
          return {
            data: {
              content: [],
              totalElements: 0,
            },
          };
        }
        throw error;
      }
    },

    _get_users_advanced: async (
      data?: IGetUsersAdvancedRequestBody,
      params?: IGetUserAdvancedParams<GalenDataUser>,
    ) => {
      try {
        const requestData: IGetUsersAdvancedRequestBody = data || [];

        return await httpRequest.post<
          PageOfUser,
          AxiosResponse<PageOfUser>,
          IGetUsersAdvancedRequestBody
        >(ApiUrlsEnum.USER_ADVANCED, requestData, {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        });
      } catch (error) {
        if (
          isAxiosError(error) &&
          error.response &&
          error.response.status === 404
        ) {
          return {
            data: {
              content: [],
              totalElements: 0,
            },
          };
        }
        throw error;
      }
    },

    reset_password: async (username: string) => {
      const params: UserManagementApiSendPasswordResetCodeUsingPOSTRequest = {
        email: username,
      };
      return await httpRequest.post<void, AxiosResponse<void>, void>(
        ApiUrlsEnum.RESET_PASSWORD,
        undefined,
        {
          params,
        },
      );
    },

    getBackendState: async (sessionId: string) => {
      try {
        return await Api.remote.get_device_data_advanced_owner_custom_criteria<BackendStatus>(
          {
            deviceDataModelId:
              process.env.REACT_APP_BACKEND_STATE_MACHINE_ID ?? '',
            deviceCriteriaGroup: {
              groupElements: [
                {
                  key: 'SessionId',
                  operator: CriteriaGroupGroupElementsInnerOperatorEnum.Equal,
                  value: sessionId,
                },
              ],
            },
          },
        );
      } catch (error) {
        if (
          isAxiosError(error) &&
          error.response &&
          error.response.status === 404
        ) {
          return {
            data: {
              content: [],
              totalElements: 0,
            },
          };
        }
        throw error;
      }
    },

    delete_user: async (userId: string) => {
      return await httpRequest.delete<void, AxiosResponse<void>, string>(
        ApiUrlsEnum.USER,
        {
          params: {
            userId,
          },
        },
      );
    },

    parse_licence_key: (key: string) => {
      console.error('parse_licence_key: obsolete');
      return {
        activationKey: '00-00-00-00-00-00-00-00',
        validDate: '1900-01-01',
        credits: 0,
      };
    },

    init: async () => {
      // console.error('init: obsolete');
      return true;
    },

    get_ip_address: async () => {
      // console.error('get_ip_address: obsolete');
      return window.location.hostname;
    },
  },

  client: {
    startWatcher: (folder: string): Promise<void> =>
      clientFunction('startWatcher', folder),

    stopWatcher: (): Promise<void> => clientFunction('stopWatcher'),

    getNewFiles: (): Promise<string[]> => clientFunction('getNewFiles'),

    exit: (): Promise<void> => clientFunction('exit'),
  },
};

(window as any).Api = Api;
export { Api };
