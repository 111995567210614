import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Badge, Button, ButtonGroup, Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';

import { faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import Sentry from 'src/services/sentry';

import ConfirmPatientInformationModal from 'src/components/ConfirmPatientInformationModal';
import CustomInputGroupWithIcon from 'src/components/custom_components/customInputGroupWithIcon';

import { Api, transformExam } from 'src/models/Api.model';
import {
  DeviceDataView,
  ExamData,
} from 'src/models/GalenData/DeviceDataView.model';
import { EntityCriteriaOperatorEnum } from 'src/models/GalenData/EntityCriteria.model';
import { OrderStatusEnum } from 'src/models/GalenData/Order.model';
import { PageOfUser } from 'src/models/GalenData/PageOfUser.model';
import { IGetUsersRequestBody } from 'src/models/GalenData/Request.model';
import { SortOrderEnum } from 'src/models/GalenData/SortOrder.model';
import { GalenDataUser } from 'src/models/GalenData/User.model';
import {
  ReportTemplate,
  ReportTemplateName,
} from 'src/models/ReportTemplate.model';
import {
  pendingStatusText,
  Session,
  SessionStatusEnum,
} from 'src/models/Session.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';
import {
  MRNPattern,
  namePattern,
  validateDatePattern,
  validateMRN,
  validateMRNLength,
  validateNameLength,
  validateNamePattern,
} from 'src/utils/validationRules';

import MainTable from './MainTable';

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export type SearchPatientInfoFormGroupProps = {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  handleSubmit: () => void;
};

export default function SearchPatientInfoFormGroup({
  session,
  setSession,
  handleSubmit,
}: SearchPatientInfoFormGroupProps) {
  const {
    register,
    control,
    setError,
    clearErrors,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<Session>();

  const handleCreateNewOrder = (patientData: GalenDataUser) => {
    setValue('firstName', patientData.firstName);
    setValue('lastName', patientData.lastName);
    setValue('id', patientData.patientId ?? '');
    setValue('dob', patientData.dateOfBirth);

    handleSubmit();
  };

  const handleStartExamWithOrder = (
    patientData: GalenDataUser,
    orderId: string,
  ) => {
    setValue('firstName', patientData.firstName);
    setValue('lastName', patientData.lastName);
    setValue('id', patientData.patientId ?? '');
    setValue('dob', patientData.dateOfBirth);
    setValue('orderId', orderId);

    handleSubmit();
  };

  const ValidFieldIcon = (field: keyof Session) => {
    return (
      getValues(field) &&
      !errors[field] && (
        <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-2" />
      )
    );
  };

  const registerDOB = {
    ...register('dob', { required: 'DOB is required' }),
    ref: undefined,
  };

  const [patientsData, setPatientsData] = useState<PageOfUser>();

  const [isGettingPatients, setIsGettingPatients] = useState(false);

  const getAllUsers = useCallback(
    async (pageNumber: number, pageSize: number) => {
      try {
        if (Object.keys(errors).length > 0) {
          return;
        }

        setIsGettingPatients(true);

        const { firstName, lastName, id, dob } = getValues();

        const filterConditions: {
          key: keyof GalenDataUser & IGetUsersRequestBody;
          value: string | undefined;
        }[] = [
          { key: 'patientId', value: id?.trim() },
          {
            key: 'dateOfBirth',
            value: dob ? moment(dob).format('YYYY-MM-DD') : undefined,
          },
          { key: 'firstName', value: firstName?.trim() },
          { key: 'lastName', value: lastName?.trim() },
        ];

        const filters = filterConditions
          .filter(({ value }) => value)
          .map(({ key, value }) => ({
            key,
            value,
            operator: EntityCriteriaOperatorEnum.Equal,
            isCustomField: false,
          }));

        const data = await Api.remote._get_users_advanced(filters, {
          pageNumber,
          pageSize,
          sortBy: ['createdOn'],
          sortOrder: SortOrderEnum.DESC,
        });

        setPatientsData(data.data);
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);
        Api.alertBox('Error', errorMessage);
      } finally {
        setIsGettingPatients(false);
      }
    },
    [errors, getValues],
  );

  useEffect(() => {
    getAllUsers(0, 10);
  }, [getAllUsers]);

  const handleGetAllUsers = useMemo(
    () => debounce(getAllUsers, 1000),
    [getAllUsers],
  );

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const handleSetIsShowConfirmModal = (isShowConfirmModal: boolean) => {
    setIsShowConfirmModal(isShowConfirmModal);
  };

  const [currentSession, setCurrentSession] = useState<Session>(session);

  return (
    <div className="d-flex flex-fill flex-column">
      <Row>
        <Form.Group as={Col} controlId="dob">
          <Form.Label className="d-flex align-items-center">
            DOB (YYYY-MM-DD) {ValidFieldIcon('dob')}
          </Form.Label>

          <Controller
            control={control}
            {...registerDOB}
            render={({ field: { onChange, value } }) => (
              <DatePicker
                id="dob"
                icon={
                  <FontAwesomeIcon
                    icon={faSearch}
                    className="text-secondary top-0 bottom-0 my-auto mx-0 pb-4"
                  />
                }
                showIcon
                wrapperClassName="w-100"
                className="form-control mb-3"
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy-MM-dd"
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                onChange={(date, e) => {
                  const target = e?.target as HTMLInputElement;
                  if (!target.value || validateDatePattern(target.value)) {
                    setValue('dob', moment(value).toDate().toDateString());
                    onChange(date);
                    handleGetAllUsers(0, 10);
                  }
                }}
                selected={value ? moment(value).toDate() : undefined}
              />
            )}
          />
          {errors.dob?.message && (
            <p className="text-danger">{errors.dob?.message}</p>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="lastName">
          <Form.Label>Last Name {ValidFieldIcon('lastName')}</Form.Label>
          <CustomInputGroupWithIcon>
            <Form.Control
              placeholder="Type Last Name"
              {...register('lastName', {
                required: 'Last name is required.',
                maxLength: {
                  value: 100,
                  message: 'Last name must be 100 characters or less.',
                },
                pattern: {
                  value: namePattern,
                  message: 'Last name contains invalid characters.',
                },
              })}
              onChange={(e) => {
                if (e.target.value) {
                  if (!validateNamePattern(e.target.value)) {
                    setError('lastName', {
                      type: 'pattern',
                      message: 'Last name contains invalid characters.',
                    });
                    return;
                  }

                  if (!validateNameLength(e.target.value)) {
                    setError('lastName', {
                      type: 'maxLength',
                      message: 'Last name must be 100 characters or less.',
                    });
                    return;
                  }
                }
                clearErrors('lastName');

                setValue('lastName', e.target.value);

                handleGetAllUsers(0, 10);
              }}
              autoComplete="off"
            />
          </CustomInputGroupWithIcon>

          {errors.lastName?.message && (
            <p className="text-danger">{errors.lastName?.message}</p>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="firstName">
          <Form.Label>First Name {ValidFieldIcon('firstName')}</Form.Label>
          <CustomInputGroupWithIcon>
            <Form.Control
              placeholder="Type First Name"
              {...register('firstName', {
                required: 'First name is required.',
                maxLength: {
                  value: 100,
                  message: 'First name must be 100 characters or less.',
                },
                pattern: {
                  value: namePattern,
                  message: 'First name contains invalid characters.',
                },
              })}
              onChange={(e) => {
                if (e.target.value) {
                  if (!validateNamePattern(e.target.value)) {
                    setError('firstName', {
                      type: 'pattern',
                      message: 'First name contains invalid characters.',
                    });
                    return;
                  }

                  if (!validateNameLength(e.target.value)) {
                    setError('firstName', {
                      type: 'maxLength',
                      message: 'First name must be 100 characters or less.',
                    });
                    return;
                  }
                }
                clearErrors('firstName');

                setValue('firstName', e.target.value);
                handleGetAllUsers(0, 10);
              }}
              autoComplete="off"
            />
          </CustomInputGroupWithIcon>

          {errors.firstName?.message && (
            <p className="text-danger">{errors.firstName?.message}</p>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="id">
          <Form.Label>MRN {ValidFieldIcon('id')}</Form.Label>

          <CustomInputGroupWithIcon>
            <Form.Control
              type="number"
              placeholder="Type MRN"
              autoComplete="off"
              {...register('id', {
                required: 'MRN is required',
                maxLength: {
                  value: 20,
                  message: 'MRN must be less than 20 characters.',
                },
                pattern: {
                  value: MRNPattern,
                  message: 'MRN must contain only numbers.',
                },
              })}
              onChange={(e) => {
                if (e.target.value) {
                  if (!validateMRNLength(e.target.value)) {
                    setError('id', {
                      type: 'maxLength',
                      message: 'MRN must be less than 20 characters.',
                    });
                    return;
                  }

                  if (!validateMRN(e.target.value)) {
                    setError('id', {
                      type: 'pattern',
                      message: 'MRN must contain only numbers.',
                    });
                    return;
                  }
                }
                clearErrors('id');

                setValue('id', e.target.value);
                handleGetAllUsers(0, 10);
              }}
            />
          </CustomInputGroupWithIcon>

          {errors.id?.message && (
            <p className="text-danger">{errors.id?.message}</p>
          )}
        </Form.Group>
      </Row>

      <MainTable
        isLoading={isGettingPatients}
        dataSource={patientsData?.content ?? []}
        pagination={{
          defaultPageSize: 10,
          pageSizeOptions: [5, 10, 20],
          totalPages: patientsData?.totalPages ?? 0,
          onChangePage: (page, pageSize) => {
            getAllUsers(page - 1, pageSize);
          },
        }}
        columns={[
          {
            title: 'DOB',
            key: 'dateOfBirth',
          },
          {
            title: 'Last Name',
            key: 'lastName',
          },
          {
            title: 'First Name',
            key: 'firstName',
          },
          {
            title: 'MRN',
            key: 'patientId',
          },
        ]}
        nestedColumns={[
          {
            title: 'Order Date',
            key: 'OrderStatusDatetime',
            render: (orderDate: string, data) => (
              <span>
                {moment(orderDate)
                  .tz(userTimeZone)
                  .format('YYYY-MM-DD HH:mm z')}
              </span>
            ),
          },
          {
            title: 'Order Type',
            key: 'OrderType',
            render: (orderType: ReportTemplate) => {
              return <span>{ReportTemplateName(orderType)}</span>;
            },
          },
          {
            title: 'Order Status',
            key: 'Status',

            render: (_, data) => {
              if (!data) {
                return;
              }
              if ('SessionId' in data.data) {
                const session = transformExam(data as DeviceDataView<ExamData>);

                return session.graded ? (
                  <div>
                    {!session.completedReason && (
                      <Badge pill bg="secondary">
                        {SessionStatusEnum.IN_PROGRESS}
                      </Badge>
                    )}
                  </div>
                ) : (
                  <Badge pill bg="secondary-200" className="text-black">
                    {pendingStatusText(session)}
                  </Badge>
                );
              }

              if (data.data.Status.value === OrderStatusEnum.OPEN) {
                return (
                  <Badge pill bg="primary">
                    {data.data.Status.value}
                  </Badge>
                );
              }

              if (data.data.Status.value === OrderStatusEnum.COMPLETE) {
                return (
                  <Badge pill bg="success">
                    {data.data.Status.value}
                  </Badge>
                );
              }

              return (
                <Badge pill bg="secondary-200" className="text-black">
                  {data.data.Status.value}
                </Badge>
              );
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (_, data) => {
              if (!data) {
                return;
              }

              return (
                <ButtonGroup aria-label="Review report and resume">
                  <Button
                    variant="primary"
                    className="border px-5 py-2"
                    disabled={
                      'SessionId' in data.data ||
                      (!('SessionId' in data.data) &&
                        data.data.Status.value !== OrderStatusEnum.OPEN)
                    }
                    onClick={() => {
                      const errorMessage = 'Order id not found in the Order';
                      if (!data.data.OrderId) {
                        Sentry.captureException(new Error(errorMessage));
                        throw new Error(errorMessage);
                      }
                      handleStartExamWithOrder(
                        data.owner,
                        data.data.OrderId.value,
                      );
                    }}
                  >
                    Start Exam
                  </Button>
                  <Button
                    variant="primary"
                    className="border px-4 py-2"
                    disabled={
                      !(
                        'SessionId' in data.data &&
                        data.data.LeftEyeResultJson &&
                        data.data.RightEyeResultJson &&
                        !data.data.CompletedReason
                      )
                    }
                    onClick={() => {
                      const session = transformExam(
                        data as DeviceDataView<ExamData>,
                      );
                      setCurrentSession(session);
                      handleSetIsShowConfirmModal(true);
                    }}
                  >
                    Resume
                  </Button>
                </ButtonGroup>
              );
            },
          },
        ]}
        handleCreateNewOrder={handleCreateNewOrder}
      />

      <ConfirmPatientInformationModal
        session={currentSession}
        setSession={setSession}
        isShowConfirmModal={isShowConfirmModal}
        handleSetIsShowConfirmModal={handleSetIsShowConfirmModal}
      />
    </div>
  );
}
