import { useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import RangeSlider from 'react-bootstrap-range-slider';

import {
  faVolumeDown,
  faVolumeHigh,
  faVolumeMute,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CameraApi } from 'src/models/Camera/CameraApi.model';

const CAMERA_VOL = 'cameraVol';
const MIN_VOL = 0;
const MAX_VOL = 100;
const INITIAL_VOL = 50;

const handleAfterChangeVol = (vol: number) => {
  CameraApi.setVolume(vol, () => {});
  window.localStorage.setItem(CAMERA_VOL, vol + '');
};

export const VolumeControl = () => {
  const [vol, updatedVol] = useState<number>(() => {
    const storedVolume = window.localStorage.getItem(CAMERA_VOL);

    return storedVolume === null ? INITIAL_VOL : +storedVolume;
  });

  const handleToggleVol = () => {
    if (vol) {
      handleAfterChangeVol(MIN_VOL);
      updatedVol(MIN_VOL);
      return;
    }

    handleAfterChangeVol(INITIAL_VOL);
    updatedVol(INITIAL_VOL);
  };

  const handleMaximizeVol = () => {
    handleAfterChangeVol(MAX_VOL);
    updatedVol(MAX_VOL);
  };

  return (
    <Stack direction="horizontal" gap={1}>
      <Button variant="light" className="p-0" onClick={handleToggleVol}>
        <FontAwesomeIcon
          icon={vol ? faVolumeDown : faVolumeMute}
          className="mx-1"
        />
      </Button>

      <RangeSlider
        value={vol}
        tooltipLabel={(currentValue) => `${currentValue}%`}
        step={10}
        onChange={(e) => updatedVol(+e.target.value)}
        onAfterChange={(e) => handleAfterChangeVol(+e.target.value)}
      />

      <Button variant="light" className="p-0" onClick={handleMaximizeVol}>
        <FontAwesomeIcon icon={faVolumeHigh} className="mx-1" />
      </Button>
    </Stack>
  );
};
