export enum ModelEnum {
  DR_US = 'DR-US',
  DR_EU = 'DR-EU',
  DR_EU_SMALL = 'DR-EU-SMALL',
  AMD_EU = 'AMD-EU',
  AMD_EU_SMALL = 'AMD-EU-SMALL',
  GLAUCOMA_EU = 'Glaucoma-EU',
  GLAUCOMA_EU_SMALL = 'Glaucoma-EU-SMALL',
  CVD_EU = 'CVD-EU',
  CVD_EU_SMALL = 'CVD-EU-SMALL',
  VESSEL_SEGMENTATION = 'vessel_seg',
}
