import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
  Stack,
} from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';

import {
  faCircleCheck,
  faCircleXmark,
  faFolder,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBan,
  faCog,
  faInfoCircle,
  faSpinner,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Sentry from 'src/services/sentry';
import { v4 as uuidv4 } from 'uuid';

import useBoundStore from 'src/store/useBoundStore';
import {
  defaultNotifierStatus,
  useNotifierStore,
} from 'src/store/useNotifierStore';

import AnalyzingProgressModal from 'src/components/AnalyzingProgressModal/AnalyzingProgressModal';

import { Api } from 'src/models/Api.model';
import { Camera } from 'src/models/Camera.model';
import { getErrorMessage } from 'src/models/GalenData/helpers/getErrorMessage';
import { initData } from 'src/models/InitialData.model';
import { ModelEnum } from 'src/models/Model.model';
import { getModels } from 'src/models/Product.model';
import { ReportTemplate } from 'src/models/ReportTemplate.model';
import { EyesPosition, Session } from 'src/models/Session.model';

import useRetake from 'src/hooks/useRetake';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';

interface ISessionUploadProps {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
}

const SessionUpload = ({ session, setSession }: ISessionUploadProps) => {
  const {
    leftUrl,
    rightUrl,
    handleSetLeftUrl,
    handleSetRightUrl,
    handleSetIsCapturing,
    product,
    practiceId,
    camera,
    getSettings,
    userId,
  } = useBoundStore();

  const { setdeviceDataId, setStatus, setModels, setSessionId } =
    useNotifierStore();

  const [leftSaved, setLeftSaved] = useState<boolean>(false);
  const [rightSaved, setRightSaved] = useState<boolean>(false);

  const [grading, setGrading] = useState(false);

  const history = useHistory();

  const handlePrevious = () => {
    history.push('/session-form');
  };

  const leftFileInputRef = useRef<HTMLInputElement>(null);
  const rightFileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadLeft = () => {
    const leftFileInput = leftFileInputRef.current;
    if (leftFileInput) {
      leftFileInput.click();
    }
  };

  const handleUploadRight = () => {
    const rightFileInput = rightFileInputRef.current;
    if (rightFileInput) {
      rightFileInput.click();
    }
  };

  const handleSelectLeft = () => {
    const leftFileInput = leftFileInputRef.current;

    if (leftFileInput?.files && leftFileInput.files[0]) {
      const file = leftFileInput.files[0];
      if (file.type === 'image/jpeg') {
        const url = URL.createObjectURL(file);
        handleSetLeftUrl(url);
      }
    }
  };

  const handleSelectRight = () => {
    const rightFileInput = rightFileInputRef.current;

    if (rightFileInput?.files && rightFileInput.files[0]) {
      const file = rightFileInput.files[0];
      if (file.type === 'image/jpeg') {
        const url = URL.createObjectURL(file);
        handleSetRightUrl(url);
      }
    }
  };

  const handleSaveLeft = () => {
    setLeftSaved(true);
    if (rightSaved) {
      handleSetIsCapturing(false);
    }
  };

  const handleSaveRight = () => {
    setRightSaved(true);
    if (leftSaved) {
      handleSetIsCapturing(false);
    }
  };

  const submitSession = async () => {
    setGrading(true);

    try {
      const formValues = Object.assign({}, session);

      if (Object.prototype.hasOwnProperty.call(formValues, 'retakeSessions')) {
        delete formValues.retakeSessions;
      }

      if (leftSaved) {
        formValues.leftImageFile = leftUrl;
        formValues.leftImageFileName = leftUrl.split('/').pop() ?? '';
      }

      if (rightSaved) {
        formValues.rightImageFile = rightUrl;
        formValues.rightImageFileName = rightUrl.split('/').pop() ?? '';
      }

      if (!practiceId) {
        throw new Error(getErrorMessage('practice-is-required'));
      }

      const models = getModels(
        product,
        camera,
        formValues.template || ReportTemplate.ASSUREPLUS_LARGE_EYE_CVD,
      );

      // filter cvd
      if (formValues.template?.indexOf('cvd') === -1) {
        models.filter((item) => item !== ModelEnum.CVD_EU);
      }

      setModels(models);

      const sessionId = uuidv4();
      setSessionId(sessionId);

      // submit session
      const iid = await Api.remote.submit_session(
        practiceId,
        userId,
        formValues,
        models,
        sessionId,
      );

      setSession(formValues);

      setdeviceDataId(true, iid);
    } catch (error) {
      Sentry.captureException(error);

      setGrading(false);
      const errorMessage = handleHttpRequestError(error);
      Api.alertBox('Error', errorMessage);
    }
  };

  const handleSetNotGrading = () => {
    setGrading(false);
  };

  const handleResetSession = () => {
    setSession(initData.tempSession);
    setStatus(defaultNotifierStatus);
  };

  const location = useLocation<{
    isResume: boolean;
  }>();

  useEffect(() => {
    if (location.state?.isResume) {
      setGrading(true);

      if (session.leftImageFile) {
        handleSetLeftUrl(session.leftImageFile);
        setLeftSaved(true);
      }

      if (session.rightImageFile) {
        handleSetRightUrl(session.rightImageFile);
        setRightSaved(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, session]);

  const [retakeEyePosition, setRetakeEyePosition] = useState('');

  const { uploadAnalyzeLeftEye, uploadAnalyzeRightEye, uploadAnalyzeBothEyes } =
    useRetake({
      session,
      setSession,
      handleSetLeftUrl,
      handleSetRightUrl,
    });

  const handleUnsetSavedEyePosition = (position: EyesPosition) => {
    if (position === EyesPosition.BOTH) {
      setLeftSaved(false);
      setRightSaved(false);
      setRetakeEyePosition(EyesPosition.BOTH);
    } else if (position === EyesPosition.LEFT) {
      setLeftSaved(false);
      setRetakeEyePosition(EyesPosition.LEFT);
    } else {
      setRightSaved(false);
      setRetakeEyePosition(EyesPosition.RIGHT);
    }
  };

  const handleRetakeAnalyze = () => {
    setGrading(true);
    setSession({ ...session, graded: false });

    if (retakeEyePosition === EyesPosition.BOTH) {
      uploadAnalyzeBothEyes(leftUrl, rightUrl);
    } else if (retakeEyePosition === EyesPosition.LEFT) {
      uploadAnalyzeLeftEye(leftUrl);
    } else {
      uploadAnalyzeRightEye(rightUrl);
    }
  };

  const settings = getSettings();
  const isClient = Api.isClient();

  const [newFiles, setNewFiles] = useState<string[]>([]);

  const parseNewFiles = (files: string[]) => {
    let newLeftUrl = '';
    let newRightUrl = '';

    // try to find the latest left and right images
    for (let i = files.length - 1; i >= 0; i--) {
      const parts = files[i].toLowerCase().split(/[_\-.]/); // the separator could be _, - or .
      if (
        !newLeftUrl &&
        (parts.includes('l') || parts.includes('left') || parts.includes('os'))
      ) {
        newLeftUrl = files[i];
        handleSetLeftUrl(newLeftUrl);
      } else if (
        !newRightUrl &&
        (parts.includes('r') || parts.includes('right') || parts.includes('od'))
      ) {
        newRightUrl = files[i];
        handleSetRightUrl(newRightUrl);
      }
    }
  };

  useEffect(() => {
    if (!session.leftImageFile) {
      handleSetLeftUrl('');
    }

    if (!session.rightImageFile) {
      handleSetRightUrl('');
    }

    let timer: any;
    let fileCount = 0;

    const checkNewFiles = () => {
      Api.client.getNewFiles().then((files: string[]) => {
        if (files.length !== fileCount) {
          fileCount = files.length;
          parseNewFiles(files);
          setNewFiles(files);
        }

        timer = setTimeout(checkNewFiles, 1000);
      });
    };

    // start monitoring when entering the page
    if (isClient) {
      Api.client
        .startWatcher(settings.folder)
        .then(() => {
          console.log('watcher started successfully', settings.folder);
          checkNewFiles();
        })
        .catch((err) => {
          console.error('Error: failed to start watcher', err);
        });
    }

    return () => {
      // stop monitoring when leaving the page
      if (isClient) {
        Api.client.stopWatcher();
        if (timer) {
          clearInterval(timer);
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // drag and drop
  const handleDragOver = (event: any) => {
    if (
      (event.target.id === 'img-left' && !leftSaved) ||
      (event.target.id === 'img-right' && !rightSaved)
    ) {
      event.preventDefault(); // allow dropping
    }
  };

  const handleDrop = (event: any) => {
    event.preventDefault();

    if (event.dataTransfer) {
      const imageUrl = event.dataTransfer.getData('text/plain');
      if (event.target.id === 'img-left') {
        handleSetLeftUrl(imageUrl);
      } else if (event.target.id === 'img-right') {
        handleSetRightUrl(imageUrl);
      }
    }
  };

  return (
    <Container fluid className="main-content optain-bg">
      <Row>
        <Col md={4}>
          <div>
            <p className="text-optain mb-0">NEW EXAM: {session.id}</p>
            <h3 className="mb-4">
              {session.lastName}, {session.firstName}
            </h3>
          </div>

          <div
            className="text-center align-middle"
            style={{ width: '100%', height: '100%' }}
          >
            {isClient && settings.folder ? (
              <Card
                className="text-start"
                style={{ height: '620px', overflowY: 'auto' }}
              >
                <Card.Header>
                  <FontAwesomeIcon icon={faFolder} className="me-2" />
                  {settings.folder}
                </Card.Header>

                <Card.Body className="ps-0 pe-3 pt-0 pb-3">
                  <Container fluid>
                    <Row>
                      {newFiles.map((file) => (
                        <Col sm={6} className="ps-3 pe-0 pt-3 pb-0" key={file}>
                          <img
                            src={new URL(file).href}
                            alt="fundus"
                            className={
                              leftUrl === new URL(file).href ||
                              rightUrl === new URL(file).href
                                ? 'border border-primary border-5 img-fluid'
                                : 'border border-dashed border-5 img-fluid'
                            }
                            draggable="true"
                          />
                        </Col>
                      ))}
                      {newFiles.length === 0 && (
                        <p className="text-center p-4">
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            className="me-2"
                          />
                          Waiting for new fundus images...
                        </p>
                      )}
                    </Row>
                  </Container>
                </Card.Body>
                <Card.Footer className="text-muted">
                  <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
                  <i>Drag and drop images to the right.</i>
                </Card.Footer>
              </Card>
            ) : (
              <Alert variant="warning" className="p-4">
                <h3>
                  <FontAwesomeIcon icon={faBan} className="me-2" />
                </h3>

                <p className="mt-4">
                  {camera === Camera.SMALL
                    ? 'The camera is not connected. '
                    : 'File monitoring is disabled. '}
                  Please use manual upload.
                </p>

                {isClient && (
                  <Button
                    variant="outline-secondary"
                    href="/#/settings"
                    className="mt-3"
                  >
                    <FontAwesomeIcon icon={faCog} className="me-2" />
                    Setup Image Folder
                  </Button>
                )}
              </Alert>
            )}
          </div>
        </Col>
        <Col md={8}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="pb-2">
              <p className="lead">Image Capture</p>
            </div>
          </div>

          <div className="d-flex justify-content-between mb-5 gap-2">
            <div>
              <div>
                <div style={{ maxWidth: 460 }}>
                  <img
                    src={leftUrl || '/img/optain-scanning.png'}
                    id="img-left"
                    alt="left eye"
                    className="img-fluid"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  />
                </div>
                <Badge
                  bg="dark"
                  className="position-relative"
                  style={{
                    top: -25,
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  Left Eye
                </Badge>
              </div>

              <div className="d-flex justify-content-center flex-md-column flex-xxl-row">
                <input
                  type="file"
                  ref={leftFileInputRef}
                  className="d-none"
                  accept=".jpg, .jpeg"
                  onChange={handleSelectLeft}
                />

                <Button
                  variant={leftSaved ? 'outline-secondary' : 'primary'}
                  onClick={handleUploadLeft}
                  disabled={leftSaved}
                >
                  <FontAwesomeIcon icon={faUpload} className="me-2" />
                  Upload
                </Button>

                <Button
                  variant={
                    leftUrl === '' || leftSaved
                      ? 'outline-secondary'
                      : 'primary'
                  }
                  className="ms-xxl-3 mt-sm-3 mt-xxl-0"
                  onClick={handleSaveLeft}
                  disabled={leftUrl === '' || leftSaved}
                >
                  <FontAwesomeIcon icon={faCircleCheck} className="me-2" />
                  <span>Accept</span>
                </Button>
              </div>
            </div>

            <div>
              <div>
                <div style={{ maxWidth: 460 }}>
                  <img
                    src={rightUrl || '/img/optain-scanning.png'}
                    id="img-right"
                    alt="right eye"
                    className="img-fluid"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  />
                </div>
                <Badge
                  bg="dark"
                  className="position-relative"
                  style={{
                    top: -25,
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  Right Eye
                </Badge>
              </div>

              <div className="d-flex justify-content-center flex-md-column flex-xxl-row">
                <input
                  type="file"
                  ref={rightFileInputRef}
                  className="d-none"
                  accept=".jpg, .jpeg"
                  onChange={handleSelectRight}
                />

                <Button
                  variant={rightSaved ? 'outline-secondary' : 'primary'}
                  onClick={handleUploadRight}
                  disabled={rightSaved}
                >
                  <FontAwesomeIcon icon={faUpload} className="me-2" />
                  Upload
                </Button>

                <Button
                  variant={
                    rightUrl === '' || rightSaved
                      ? 'outline-secondary'
                      : 'primary'
                  }
                  className="ms-xxl-3 mt-sm-3 mt-xxl-0"
                  onClick={handleSaveRight}
                  disabled={rightUrl === '' || rightSaved}
                >
                  <FontAwesomeIcon icon={faCircleCheck} className="me-2" />
                  <span>Accept</span>
                </Button>
              </div>
            </div>
          </div>
          <Stack
            direction="horizontal"
            gap={3}
            className="d-flex justify-content-end pt-2"
          >
            <Button
              variant="light text-optain"
              className="me-4 w-200px shadow"
              onClick={handlePrevious}
            >
              Previous
            </Button>
            <Button
              variant="optain"
              className="me-4 w-200px shadow"
              onClick={() => {
                retakeEyePosition ? handleRetakeAnalyze() : submitSession();
              }}
              disabled={!leftSaved || !rightSaved}
            >
              Submit
            </Button>

            <Button
              variant="warning"
              className="shadow"
              onClick={() => {
                handleSetLeftUrl('');
                handleSetRightUrl('');
              }}
              disabled={leftUrl === '' && rightUrl === ''}
            >
              <FontAwesomeIcon icon={faCircleXmark} className="me-2" />
              Reset
            </Button>
          </Stack>
        </Col>
      </Row>

      <AnalyzingProgressModal
        leftSaved={leftSaved}
        handleUnsetSavedEyePosition={handleUnsetSavedEyePosition}
        rightSaved={rightSaved}
        session={session}
        isShowAnalyzingProgressModal={grading}
        handleCloseAnalyzingProgressModal={handleSetNotGrading}
        handleResetSession={handleResetSession}
        handleSetLeftUrl={handleSetLeftUrl}
        handleSetRightUrl={handleSetRightUrl}
      />
    </Container>
  );
};

export default SessionUpload;
