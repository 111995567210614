import { useMemo } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  Row,
  Stack,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import useBoundStore from 'src/store/useBoundStore';

import { PatientInfoKeyMapOptain, Session } from 'src/models/Session.model';

interface ISessionHintProps {
  session: Session;
}

const SessionHint = ({ session }: ISessionHintProps) => {
  const history = useHistory();

  const handlePrevious = () => {
    history.push('/');
  };

  const patientInfoConfirmation = useMemo(() => {
    const { id, firstName, lastName, dob } = session;
    return {
      id,
      firstName,
      lastName,
      dob,
    };
  }, [session]);

  const { handleStartCaptureBoth } = useBoundStore();

  const handleStartImaging = () => {
    history.push('/session-preview');
    handleStartCaptureBoth();
  };

  return (
    <Container fluid className="main-content">
      <Stack className="w-100" style={{ height: 520 }} gap={4}>
        <Row>
          <Col>
            <Image
              src="/img/review-patient-info.jpg"
              alt="Review information"
              fluid
            />
            <p className="text-optain mt-2">STEP 1: REVIEW INFORMATION</p>

            <p>
              Please review and confirm your name and date of birth are correct.
            </p>

            {(
              Object.keys(patientInfoConfirmation) as Array<
                keyof Session & keyof typeof PatientInfoKeyMapOptain
              >
            ).map((objKey) => (
              <Form.Group as={Row} className="mb-1" key={objKey}>
                <Form.Label column sm={6} xxl={5} className="lead">
                  {PatientInfoKeyMapOptain[objKey]}:
                </Form.Label>
                <Col>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={patientInfoConfirmation[objKey]?.toString()}
                  />
                </Col>
              </Form.Group>
            ))}
          </Col>
          <Col>
            <Image src="/img/optain-hint1.jpg" alt="Setup" fluid />
            <p className="text-optain mt-2">STEP 2: SETUP</p>
            <p>Photographing your eyes is easy.</p>
            <p>
              Sit down and rest your head on the device as shown in the photo
              above.
            </p>
            <p>
              Find a comfortable position. Make sure you’re stable and that you
              can hold your position for ~60 seconds.
            </p>
          </Col>
          <Col>
            <Image
              src="/img/optain-hint2.jpg"
              alt="Identifying the retina"
              fluid
            />
            <p className="text-optain mt-2">STEP 3: IDENTIFYING THE RETINA</p>
            <p>
              You’ll be guided through the imaging process by voice prompts.
            </p>
            <p>
              Look for a “cross” that resembles the icon above. Feel free to
              blink during this period.
            </p>
            <p>
              The voice prompts may ask you to change your positioning if the
              camera has trouble finding your eye.
            </p>
          </Col>
          <Col>
            <Image src="/img/optain-hint3.jpg" alt="Taking the photo" fluid />
            <p className="text-optain mt-2">STEP 4: TAKING THE PHOTO</p>
            <p>
              You’ll see two red lines when the camera is about to take the
              photo.
            </p>
            <p>
              Keep your eyes open and do not blink! You’ll see a brief flash -
              an image has been taken.
            </p>
            <p>The camera will automatically move onto your next eye.</p>
          </Col>
        </Row>
      </Stack>

      <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-md-5 mt-xxl-3">
        <Button
          variant="light text-optain"
          className="me-4 w-200px shadow"
          onClick={handlePrevious}
        >
          Previous
        </Button>
        <Button
          variant="optain"
          className="w-200px shadow"
          onClick={handleStartImaging}
        >
          Start Imaging
        </Button>
      </div>
    </Container>
  );
};

export default SessionHint;
