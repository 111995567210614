export enum AUTH_INFO {
  AUTH_TOKEN = 'authToken',
  REFRESH_TOKEN = 'refreshToken',
}

export enum CURRENT_USER {
  USERNAME = 'username',
  USER_TYPE = 'userType',
  COUNTRY = 'country',
  ORGANIZATION = 'organization',
  FULL_NAME = 'fullName',
  LAST_LOGIN = 'lastLogin',
  USER_ID = 'userId',
}

export enum LOCALSTORAGE_KEY {
  AUTH_INFO = 'authInfo',
  CURRENT_USER = 'currentUser',
  LOGO = 'logo',
  SETTINGS = 'settings',
}
