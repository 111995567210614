import { faHeart as faHeartOpen } from '@fortawesome/free-regular-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Disease } from './Disease.model';
import { FindGrading } from './Grading.model';
import { Session } from './Session.model';

enum CvdKey {
  RISK = 'pred',
  RANK = 'rank',
}

enum CvdLevel {
  GOOD = 'good',
  FAIR = 'fair',
  POOR = 'poor',
  UNGRADABLE = 'ungradable',
  NA = 'na',
}

const getRiskOrRank = (results: any) => {
  const risk = FindGrading(results, Disease.CVD_RISK);
  const rank = FindGrading(results, Disease.CVD_RANK);

  return {
    risk: typeof risk === 'number' ? risk : undefined,
    rank: typeof rank === 'number' ? rank : undefined,
  };
};

const isValidCvdAge = (age: number | undefined) => {
  return age !== undefined && age >= 40 && age <= 80;
};

const roundToDecimal = (value: number, decimals: number) => {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
};

const calculateAverage = (
  odValue: number,
  osValue: number,
  isRisk: boolean,
) => {
  const average = (odValue + osValue) / 2;
  return isRisk ? roundToDecimal(average, 1) : Math.round(average);
};

const CalculateCommonCvdValue = (
  session: Session,
  key: CvdKey,
): number | undefined => {
  const od = getRiskOrRank(session.rightResults);
  const os = getRiskOrRank(session.leftResults);

  if (od.risk === undefined && os.risk === undefined) {
    return undefined; // N/A
  }

  if (!isValidCvdAge(session.age)) {
    return undefined; // N/A
  }

  // both numbers
  if (
    od.risk !== undefined &&
    od.rank !== undefined &&
    os.risk !== undefined &&
    os.rank !== undefined
  ) {
    return key === CvdKey.RISK
      ? calculateAverage(od.risk, os.risk, true)
      : calculateAverage(od.rank * 100, os.rank * 100, false);
  }

  // single number
  if (od.risk !== undefined && od.rank !== undefined) {
    return key === CvdKey.RISK
      ? roundToDecimal(od.risk, 1)
      : Math.round(od.rank * 100);
  }

  if (os.risk !== undefined && os.rank !== undefined) {
    return key === CvdKey.RISK
      ? roundToDecimal(os.risk, 1)
      : Math.round(os.rank * 100);
  }

  // no numbers
  return undefined; // N/A
};

const CalculateCvdValue = (
  session: Session,
  key: CvdKey,
): number | undefined => {
  if (!session.graded) {
    return undefined;
  }

  return CalculateCommonCvdValue(session, key);
};

const convertLevel = (score: number): CvdLevel => {
  if (score < 10) {
    return CvdLevel.GOOD;
  } else if (score < 20) {
    return CvdLevel.FAIR;
  } else {
    return CvdLevel.POOR;
  }
};

const CalculateCvdLevel = (session: Session): CvdLevel => {
  if (!session.graded) {
    return CvdLevel.NA;
  }

  const num = CalculateCvdValue(session, CvdKey.RISK);

  return num === undefined ? CvdLevel.NA : convertLevel(num);
};

const CvdIndicator = (level: CvdLevel): JSX.Element => {
  let num = 0;
  let disabled = false;

  switch (level) {
    case CvdLevel.GOOD: {
      num = 3;
      break;
    }
    case CvdLevel.FAIR: {
      num = 2;
      break;
    }
    case CvdLevel.POOR: {
      num = 1;
      break;
    }
    case CvdLevel.UNGRADABLE:
    case CvdLevel.NA: {
      num = 3;
      disabled = true;
      break;
    }
    default: {
      console.log(`Unsupported cvd level: ${level}`);
    }
  }

  const prefix = [];
  const content = [];

  for (let i = 0; i < 3 - num; i++) {
    prefix.push('cvd-disabled');
  }

  for (let i = 0; i < num; i++) {
    content.push(disabled ? 'cvd-disabled' : 'cvd-enabled');
  }

  return (
    <>
      {prefix.map((cls, i) => (
        <FontAwesomeIcon
          icon={faHeartOpen}
          size="lg"
          className={[cls, 'ms-1'].join(' ')}
          key={i}
        />
      ))}

      {content.map((cls, i) => (
        <FontAwesomeIcon
          icon={faHeart}
          size="lg"
          className={[cls, 'ms-1'].join(' ')}
          key={i}
        />
      ))}
    </>
  );
};

const CvdLevelName = (level: CvdLevel): string => {
  switch (level) {
    case CvdLevel.GOOD: {
      return 'Good';
    }
    case CvdLevel.FAIR: {
      return 'Fair';
    }
    case CvdLevel.POOR: {
      return 'Poor';
    }
    case CvdLevel.UNGRADABLE: {
      return 'Ungradable';
    }
    case CvdLevel.NA: {
      return 'N/A';
    }
    default: {
      console.log(`Unsupported cvd level: ${level}`);
      return level;
    }
  }
};

export {
  CalculateCvdLevel,
  CalculateCvdValue,
  CvdIndicator,
  CvdKey,
  CvdLevel,
  CvdLevelName,
  isValidCvdAge,
};
