import React, { ReactNode } from 'react';
import { Stack, StackProps } from 'react-bootstrap';

import { faFolderBlank } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface EmptyProps extends StackProps {
  text?: string | ReactNode;
}
export default function Empty({
  text = 'No matching records found.',
  ...props
}: EmptyProps) {
  return (
    <Stack gap={3} {...props}>
      <FontAwesomeIcon size="3x" icon={faFolderBlank} />
      <h3 className="text-center">{text}</h3>
    </Stack>
  );
}
