import { useEffect } from 'react';

import { Session } from 'src/models/Session.model';

interface ISessionTabProps {
  session: Session;
}

const SessionTab: React.FC<ISessionTabProps> = ({ session }) => {
  useEffect(() => {
    session.graded
      ? (window.location.href = '#/session-preview') // TODO: The session-result page should be deleted
      : (window.location.href = '#/session-form');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, ['session']);

  return <></>;
};

export default SessionTab;
