import { Text, View } from '@react-pdf/renderer';

import { CalculateCvdValue, CvdKey } from 'src/models/CvdLevel.model';
import { Disease, DiseaseFullName } from 'src/models/Disease.model';
import { handleNSI18Next } from 'src/models/Language.model';
import { ReportType } from 'src/models/ReportType.model';
import { Session } from 'src/models/Session.model';

import { cls } from 'src/utils/reactPdfStyles';

interface ICvdSectionProps {
  session: Session;
  type: ReportType;
  showNotes: boolean;
}

const CvdSection = ({ session, type, showNotes }: ICvdSectionProps) => {
  // cvd risk and rank
  const risk = CalculateCvdValue(session, CvdKey.RISK);
  const rank = CalculateCvdValue(session, CvdKey.RANK);

  const { handleCommonT, handleReportT } = handleNSI18Next();

  return (
    <>
      <View style={cls('d-flex hstack justify-content-between')}>
        <View style={cls('td px-0 border-0 w-50')}>
          <Text style={cls('vascular-circle')}>
            {risk === undefined ? (
              handleCommonT('exception.NA')
            ) : (
              <>
                {risk}
                <Text style={cls('small')}>%</Text>
              </>
            )}
          </Text>
        </View>

        <View style={cls('td px-3 py-0 border-0')}></View>

        <View style={cls('td px-0 border-0 w-50')}>
          <Text style={cls('vascular-circle')}>
            {rank === undefined ? (
              handleCommonT('exception.NA')
            ) : (
              <>
                {rank}
                <Text style={cls('small')}>%</Text>
              </>
            )}
          </Text>
        </View>
      </View>

      <View style={cls('d-flex hstack justify-content-between')}>
        <View style={cls('td px-0 border-0 w-50 text-center')}>
          <Text style={cls('strong')}>
            {handleCommonT(
              `diseaseFullName.${DiseaseFullName(Disease.CVD_RISK)}`,
            )}
          </Text>
        </View>

        <View style={cls('td px-3 py-0 border-0')}></View>

        <View style={cls('td px-0 border-0 w-50 text-center')}>
          <Text style={cls('strong')}>
            {handleCommonT(
              `diseaseFullName.${DiseaseFullName(Disease.CVD_RANK)}`,
            )}
          </Text>
        </View>
      </View>

      {showNotes && (
        <View style={cls('report-text mt-4')}>
          <View style={cls('p-4 pb-2')}>
            {type === ReportType.CLINICAL ? (
              <Text style={cls('p')}>
                {handleReportT('cvdSection.referToTheEndText')}
              </Text>
            ) : (
              <>
                <Text style={cls('p')}>
                  {handleReportT('resultSection.patient.cvdContactText')}
                </Text>
                <Text style={cls('p')}>
                  {handleReportT(
                    'resultSection.patient.artifactWarningTextAlt',
                  )}
                </Text>
              </>
            )}
          </View>
        </View>
      )}
    </>
  );
};

export default CvdSection;
