import moment from 'moment';

import { Api } from './Api.model';
import { ApplicationInstance } from './Application.model';
import { BackendType } from './BackendType.model';
import { Camera } from './Camera.model';
import { ModelState } from './ModelState.model';
import { Product } from './Product.model';
import { Session } from './Session.model';
import { System } from './System.model';

// TODO: hard code payload for optain_small
const payload = {
  variant: 'optain_small',
  system: System.LINUX,
  backend: BackendType.GALENDATA,
  init: true,
  licence: {
    credits: undefined,
    machineId: '50-50-50-50-50-50-50-50',
    activationKey: '09-45-89-d9-ea-b0-ac-59',
    validDate: '2025-01-01',
    subuser: true,
  },
  oldFolders: [],
};

// ---- variant ----
// "assure_large", "assureplus_large", ..
const variant = payload.variant || '';
const parts = variant.split('_');
const product = parts.length === 2 ? variant.split('_')[0] : 'assure';
const camera = parts.length === 2 ? variant.split('_')[1] : 'large';
const system = payload.system || 'Windows';
const backend = payload.backend || 'optain';

// ---- app ----
const app = ApplicationInstance(product as Product, camera as Camera);
app.system = system;
app.backend = backend;

const templates = app.templates;

// ---- init ----
const init: boolean = payload.init || false;

// ---- sessions ----
const sessions: Session[] = [];

// ---- api ----
const api = Api;

const modelState: ModelState = {
  loadedModels: 0,
  totalModels: 0,
};

// ---- temp session ----
const tempSession: Session = {
  iid: '0',
  username: '',
  template: templates[0],
  id: '',
  firstName: '',
  lastName: '',
  age: undefined,
  gender: undefined,
  date: moment().format('YYYY-MM-DD'),
  time: moment().format('HH:mm:ss'),
  leftImageFile: undefined,
  rightImageFile: undefined,
  graded: false,
  leftResults: [],
  rightResults: [],
  leftSegmentations: [],
  rightSegmentations: [],
  notes: undefined,
  appointment: undefined,
  createdAt: new Date(),

  leftEyeDilatedTime: undefined,
  rightEyeDilatedTime: undefined,

  completedTime: undefined,
  completedReason: undefined,

  leftImageFileName: '',
  rightImageFileName: '',

  sessionId: '',
};

const oldFolders: string[] = payload.oldFolders || [];

const initData = {
  init,
  variant,
  app,
  templates,
  sessions,
  modelState,
  api,
  tempSession,
  oldFolders,
};

export { initData };
