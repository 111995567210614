import { Dispatch, FunctionComponent, SetStateAction, useMemo } from 'react';
import { Button, Card, Col, Form, Modal, Row, Stack } from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import useBoundStore from 'src/store/useBoundStore';

import { Camera } from 'src/models/Camera.model';
import { PatientInfoKeyMapOptain, Session } from 'src/models/Session.model';

interface ConfirmPatientInformationModalProps {
  session: Session;
  setSession: Dispatch<SetStateAction<Session>>;
  isShowConfirmModal: boolean;
  handleSetIsShowConfirmModal: (isShowConfirmModal: boolean) => void;
}

const ConfirmPatientInformationModal: FunctionComponent<
  ConfirmPatientInformationModalProps
> = ({
  session,
  setSession,
  isShowConfirmModal,
  handleSetIsShowConfirmModal,
}) => {
  const history = useHistory();

  const patientInfoConfirmation = useMemo(() => {
    const { id, firstName, lastName, dob } = session;
    return { id, firstName, lastName, dob };
  }, [session]);

  const handlePushToSessionPreviewPage = () => {
    history.push({
      pathname: '/session-preview',
      state: { isResume: true },
    });
  };

  const handlePushToSessionUploadPage = () => {
    history.push({
      pathname: '/session-upload',
      state: { isResume: true },
    });
  };

  const {
    handleSubmit,
    register,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      leftEyeDilated: !!session.leftEyeDilatedTime,
      rightEyeDilated: !!session.rightEyeDilatedTime,
    },
  });

  const { camera } = useBoundStore();

  const onSubmit: SubmitHandler<{
    leftEyeDilated: boolean;
    rightEyeDilated: boolean;
  }> = (data) => {
    if (isDirty) {
      const updatedSession = {
        ...session,
        leftEyeDilatedTime: data.leftEyeDilated ? new Date() : undefined,
        rightEyeDilatedTime: data.rightEyeDilated ? new Date() : undefined,
      };

      setSession(updatedSession);
    } else {
      setSession(session);
    }

    if (camera === Camera.SMALL) {
      handlePushToSessionPreviewPage();
    } else {
      handlePushToSessionUploadPage();
    }
  };

  return (
    <Modal
      show={isShowConfirmModal}
      onHide={() => handleSetIsShowConfirmModal(false)}
      backdrop="static"
      animation={false}
      scrollable={true}
      fullscreen="xl-down"
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm to Resume</Modal.Title>
      </Modal.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Card className="mb-4">
            <Card.Body className="py-2">
              {(
                Object.keys(patientInfoConfirmation) as Array<
                  keyof Session & keyof typeof PatientInfoKeyMapOptain
                >
              ).map((objKey) => (
                <Form.Group as={Row} className="mb-3" key={objKey}>
                  <Form.Label column sm="3">
                    {PatientInfoKeyMapOptain[objKey]}:
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      readOnly
                      defaultValue={patientInfoConfirmation[objKey]}
                    />
                  </Col>
                </Form.Group>
              ))}
            </Card.Body>
          </Card>
          <hr />
          <Stack direction="horizontal" className="justify-content-around">
            <Form.Group
              controlId="leftEyeDilated"
              className="d-flex align-items-center gap-2"
            >
              <FormCheckLabel>Left Eye Dilated:</FormCheckLabel>

              <FormCheckInput
                type="checkbox"
                {...register('leftEyeDilated')}
                className="mt-0"
              />
            </Form.Group>

            <Form.Group
              controlId="rightEyeDilated"
              className="d-flex align-items-center gap-2"
            >
              <FormCheckLabel>Right Eye Dilated:</FormCheckLabel>

              <FormCheckInput
                type="checkbox"
                {...register('rightEyeDilated')}
                className="mt-0"
              />
            </Form.Group>
          </Stack>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            className="text-black w-200px"
            onClick={() => handleSetIsShowConfirmModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="text-black w-200px"
            type="submit"
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ConfirmPatientInformationModal;
