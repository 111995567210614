enum EUCountry {
  Austria = 'AT',
  Belgium = 'BE',
  Bulgaria = 'BG',
  Croatia = 'HR',
  Cyprus = 'CY',
  CzechRepublic = 'CZ',
  Denmark = 'DK',
  Estonia = 'EE',
  Finland = 'FI',
  France = 'FR',
  Germany = 'DE',
  Greece = 'GR',
  Hungary = 'HU',
  Ireland = 'IE',
  Italy = 'IT',
  Latvia = 'LV',
  Lithuania = 'LT',
  Luxembourg = 'LU',
  Malta = 'MT',
  Netherlands = 'NL',
  Poland = 'PL',
  Portugal = 'PT',
  Romania = 'RO',
  Slovakia = 'SK',
  Slovenia = 'SI',
  Spain = 'ES',
  Sweden = 'SE',
  Europe = 'EU',
}

function isEU(value: string): boolean {
  return Object.values(EUCountry).includes(value as EUCountry);
}

enum Region {
  EU = 'EU',
  US = 'US',
  Global = 'Global',
}

const whichRegion = (country: string): Region => {
  if (isEU(country)) return Region.EU;
  if (country === Region.US) return Region.US;
  if (country === Region.Global) return Region.Global;
  throw new Error('Region not found');
};

export { EUCountry, isEU, Region, whichRegion };
