import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Pagination,
  Row,
  Spinner,
  Table,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import {
  faClose,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import useBoundStore from 'src/store/useBoundStore';

import { Api } from 'src/models/Api.model';
import { CameraData } from 'src/models/CameraData.model';
import { CameraMode } from 'src/models/CameraMode.model';
import { SortField } from 'src/models/GalenData/SortField.model';
import { SortOrder } from 'src/models/GalenData/SortOrder.model';
import { adminRoleList } from 'src/models/GalenData/UserRole.model';
import { Session } from 'src/models/Session.model';

import handleHttpRequestError from 'src/utils/handleHttpRequestError';
import { validateSearchValue } from 'src/utils/validationRules';

import SessionTableRow from './SessionTableRow';

interface IResultsTabProps {
  cameraMode: CameraMode;
  cameraData: CameraData | undefined;

  sessionsPage: number;
  sessionsTotal: number;
  setSession: Dispatch<SetStateAction<Session>>;
  setSessionsPage: Dispatch<SetStateAction<number>>;
  setSessionsTotal: Dispatch<SetStateAction<number>>;
}

const ResultsTab: FC<IResultsTabProps> = ({
  cameraMode,
  cameraData,
  sessionsPage,
  sessionsTotal,
  setSessionsPage,
  setSessionsTotal,
  setSession,
}) => {
  const [sessions, setSessions] = useState<Session[]>([]);
  const [sessionsSortField, setSessionsSortField] = useState<SortField>(
    SortField.EXAM_DATETIME,
  );
  const [sessionsSortOrder, setSessionsSortOrder] = useState<SortOrder>('DESC');

  const [sessionsSearch, setSessionsSearch] = useState<string>('');

  // overwrite page size
  Api.consts.SESSIONS_PER_PAGE = 10;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setFocus,
    formState: { errors, isDirty, isValid },
  } = useForm({
    defaultValues: {
      searchValue: '',
    },
  });

  const handleReset = () => {
    reset();
    handleLoadSessions(0, '', sessionsSortOrder);
  };

  const handleSort = (field: SortField) => {
    let sortField = sessionsSortField;
    let sortOrder = sessionsSortOrder;
    if (field === sessionsSortField) {
      // change order
      sortOrder = sessionsSortOrder === 'ASC' ? 'DESC' : 'ASC';
    } else {
      // change field
      sortField = field;
      sortOrder = 'ASC';
    }
    setSessionsSortField(sortField);
    setSessionsSortOrder(sortOrder);

    handlePage(sessionsPage, field, sortOrder);
  };

  const [isLoading, setIsLoading] = useState(false);

  const { userType, getSettings } = useBoundStore();
  const settings = getSettings();

  const handleLoadSessions = useCallback(
    async (page: number, searchValue: string, sessionsSortOrder: SortOrder) => {
      setIsLoading(true);

      setSessionsSearch(searchValue);

      try {
        const res = await Api.remote.load_sessions_with_retake_sessions(
          page,
          Api.consts.SESSIONS_PER_PAGE,
          searchValue,
          sessionsSortOrder,
        );

        if (res.total === 0 && isDirty) {
          setSessions(res.sessions);
          setSessionsTotal(res.total);
          return;
        }

        setSessions(res.sessions);
        setSessionsTotal(res.total);
        setSessionsPage(page);
      } catch (error) {
        const errorMessage = handleHttpRequestError(error);

        Api.alertBox('Error', `Failed to load sessions. ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    },
    [
      setSessionsSearch,
      isDirty,
      setSessions,
      setSessionsTotal,
      setSessionsPage,
    ],
  );

  const handlePage = useCallback(
    (
      page: number,
      sessionsSortField: SortField,
      sessionsSortOrder: SortOrder,
    ) => {
      // first
      if (page === -1) {
        handlePage(0, sessionsSortField, sessionsSortOrder);
        return;
      }

      // prev
      if (page === -2) {
        handlePage(sessionsPage - 1, sessionsSortField, sessionsSortOrder);
        return;
      }

      // next
      if (page === -3) {
        handlePage(sessionsPage + 1, sessionsSortField, sessionsSortOrder);
        return;
      }

      // last
      if (page === -4) {
        handlePage(
          Math.ceil(sessionsTotal / Api.consts.SESSIONS_PER_PAGE) - 1,
          sessionsSortField,
          sessionsSortOrder,
        );
        return;
      }

      if (
        page >= 0 &&
        (page < Math.ceil(sessionsTotal / Api.consts.SESSIONS_PER_PAGE) ||
          sessionsTotal === 0)
      ) {
        console.log(
          `go to page ${
            page + 1
          }, search ${sessionsSearch}, order by ${sessionsSortField} ${sessionsSortOrder}`,
        );

        handleLoadSessions(page, sessionsSearch, sessionsSortOrder);
      }
    },
    [handleLoadSessions, sessionsPage, sessionsSearch, sessionsTotal],
  );

  const handleSearch = useCallback(
    (data: { searchValue: string }) => {
      handleLoadSessions(0, data.searchValue, sessionsSortOrder);
    },
    [handleLoadSessions, sessionsSortOrder],
  );

  const location = useLocation<{
    keyword: string;
  }>();

  const submitRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (location.state?.keyword) {
      setValue('searchValue', location.state?.keyword, {
        shouldValidate: true,
      });
      setFocus('searchValue');

      submitRef.current?.requestSubmit();
    }
  }, [handleSearch, handleSubmit, location.state?.keyword, setFocus, setValue]);

  useEffect(() => {
    // reload
    handlePage(0, sessionsSortField, sessionsSortOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAdmin = !!(userType && adminRoleList.includes(userType));

  return (
    <Container fluid className="main-content">
      <Form onSubmit={handleSubmit(handleSearch)} ref={submitRef}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="searchValue">
            <InputGroup>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="Search by name, MRN, or patient date of birth (YYYY-MM-DD)"
                {...register('searchValue', {
                  maxLength: {
                    value: 100,
                    message: 'The search must be less than 100 characters.',
                  },
                  validate: {
                    isValid: (value) =>
                      validateSearchValue(value) ||
                      'Please enter a valid name, MRN, or date (YYYY-MM-DD).',
                  },
                  onChange: (event) =>
                    setValue('searchValue', event.target.value, {
                      shouldValidate: true,
                    }),
                })}
              />

              {sessionsSearch ? (
                <Button
                  variant="outline-warning"
                  style={{ height: 55 }}
                  onClick={handleReset}
                >
                  <FontAwesomeIcon icon={faClose} />
                </Button>
              ) : (
                <Button
                  variant="outline-primary"
                  type="submit"
                  style={{ height: 55 }}
                >
                  <FontAwesomeIcon
                    icon={faSearch}
                    className={classNames({
                      'icon-shake': isValid && isDirty,
                    })}
                  />
                </Button>
              )}
            </InputGroup>
          </Form.Group>
        </Row>
        {errors.searchValue?.message && (
          <p className="text-danger">{errors.searchValue?.message}</p>
        )}
      </Form>

      <Row>
        <Col>
          <div className="border bg-white overflow-hidden shadow">
            {isLoading ? (
              <div className="d-flex justify-content-center">
                <Spinner className="mx-5 my-5" variant="optain" />
              </div>
            ) : (
              <Table bordered className="m-0 align-middle" responsive>
                <thead>
                  <tr className="table-light">
                    {isAdmin && (
                      <th style={{ minWidth: '80px' }}>User Email</th>
                    )}
                    <th style={{ minWidth: '160px' }}>
                      Date Time
                      <a
                        href="#/results"
                        onClick={() => {
                          handleSort(SortField.EXAM_DATETIME);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            sessionsSortField === 'datetime'
                              ? sessionsSortOrder === 'ASC'
                                ? faSortUp
                                : faSortDown
                              : faSort
                          }
                          className="px-2 py-1 float-end"
                        ></FontAwesomeIcon>
                      </a>
                    </th>
                    <th style={{ minWidth: '160px' }}>Patient MRN</th>
                    <th style={{ minWidth: '160px' }}>Patient Name</th>
                    <th style={{ width: '120px' }}>DOB</th>
                    <th style={{ width: '200px' }}>
                      Session Details <br /> (# of attempts)
                    </th>
                    <th>Session Status</th>
                    <th style={{ width: '175px' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sessions.length === 0 ? (
                    <tr>
                      <td colSpan={20}>No matching records found.</td>
                    </tr>
                  ) : (
                    sessions.map((session) => (
                      <SessionTableRow
                        cameraMode={cameraMode}
                        cameraData={cameraData}
                        setSession={setSession}
                        key={session.iid}
                        session={session}
                        settings={settings}
                        showUsername={isAdmin}
                      />
                    ))
                  )}
                </tbody>
              </Table>
            )}
          </div>

          {!isLoading && (
            <div className="p-4">
              <Pagination className="mt-2 mb-0 justify-content-center">
                <Pagination.First
                  onClick={() => {
                    handlePage(-1, sessionsSortField, sessionsSortOrder);
                  }}
                  disabled={sessionsPage === 0}
                />
                <Pagination.Prev
                  onClick={() => {
                    handlePage(-2, sessionsSortField, sessionsSortOrder);
                  }}
                  disabled={sessionsPage === 0}
                />

                {Api.range(
                  Math.max(0, sessionsPage - 4),
                  Math.min(
                    sessionsPage + 5,
                    Math.ceil(sessionsTotal / Api.consts.SESSIONS_PER_PAGE),
                  ),
                ).map((i) => (
                  <Pagination.Item
                    active={i === sessionsPage}
                    key={i}
                    onClick={() => {
                      handlePage(i, sessionsSortField, sessionsSortOrder);
                    }}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  onClick={() => {
                    handlePage(-3, sessionsSortField, sessionsSortOrder);
                  }}
                  disabled={
                    sessionsPage >=
                    Math.ceil(sessionsTotal / Api.consts.SESSIONS_PER_PAGE) - 1
                  }
                />
                <Pagination.Last
                  onClick={() => {
                    handlePage(-4, sessionsSortField, sessionsSortOrder);
                  }}
                  disabled={
                    sessionsPage >=
                    Math.ceil(sessionsTotal / Api.consts.SESSIONS_PER_PAGE) - 1
                  }
                />
              </Pagination>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ResultsTab;
