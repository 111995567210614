import { Camera } from './Camera.model';
import { ModelEnum } from './Model.model';
import { ReportTemplate } from './ReportTemplate.model';

enum Product {
  ASSUREPLUS = 'assureplus',
  OPTAIN = 'optain',
}

export const getModels = (
  product: Product,
  camera: Camera,
  template: ReportTemplate,
): ModelEnum[] => {
  if (product === Product.OPTAIN && camera === Camera.SMALL) {
    return [ModelEnum.DR_US];
  }

  if (product === Product.ASSUREPLUS && camera === Camera.LARGE) {
    return template.includes('cvd')
      ? [
          ModelEnum.DR_EU,
          ModelEnum.AMD_EU,
          ModelEnum.GLAUCOMA_EU,
          ModelEnum.CVD_EU,
        ]
      : [ModelEnum.DR_EU, ModelEnum.AMD_EU, ModelEnum.GLAUCOMA_EU];
  }

  if (product === Product.ASSUREPLUS && camera === Camera.SMALL) {
    return template.includes('cvd')
      ? [
          ModelEnum.DR_EU_SMALL,
          ModelEnum.AMD_EU_SMALL,
          ModelEnum.GLAUCOMA_EU_SMALL,
          ModelEnum.CVD_EU_SMALL,
        ]
      : [
          ModelEnum.DR_EU_SMALL,
          ModelEnum.AMD_EU_SMALL,
          ModelEnum.GLAUCOMA_EU_SMALL,
        ];
  }

  throw new Error(`Unknown product ${product} and camera ${camera}`);
};

const ProductName = (product: Product): string => {
  switch (product) {
    case Product.ASSUREPLUS: {
      return 'Assure Plus';
    }
    case Product.OPTAIN: {
      return 'Optain';
    }

    default: {
      return `Unknown: ${product}`;
    }
  }
};

const ProductFullName = (product: Product): string => {
  switch (product) {
    case Product.ASSUREPLUS: {
      return 'Eyetelligence Assure Plus';
    }
    case Product.OPTAIN: {
      return 'Optain Workstation';
    }

    default: {
      return `Unknown: ${product}`;
    }
  }
};

export { Product, ProductFullName, ProductName };
