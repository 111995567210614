import Sentry from 'src/services/sentry';

import { useNotifierStore } from 'src/store/useNotifierStore';

import { Api } from 'src/models/Api.model';
import { GlobalStatus } from 'src/models/GalenData/DeviceDataView.model';
import { Session } from 'src/models/Session.model';

interface ISessionNotifierProps {
  sessionsPage: number;

  setSession: React.Dispatch<React.SetStateAction<Session>>;
}

const SessionNotifier: React.FC<ISessionNotifierProps> = ({ setSession }) => {
  let waitingSeconds = 0;

  // Have to use getState to avoid infinite loop
  const { waiting, deviceDataId, sessionId, setWaiting, setStatus } =
    useNotifierStore.getState();

  const SessionNotifier = async () => {
    try {
      const res = await Api.remote.getBackendState(sessionId);

      const content = res.data.content ?? [];

      if (content.length === 0) {
        setTimeout(SessionNotifier, 500);
        return;
      }

      const data = res.data.content?.[0].data;

      if (!data) {
        setTimeout(SessionNotifier, 500);
        return;
      }

      setStatus(data);

      if (
        data?.GlobalStatus.value === GlobalStatus.Failed ||
        data?.GlobalStatus.value === GlobalStatus.ReportEnd
      ) {
        onSessionComplete(deviceDataId);
      } else {
        if (waitingSeconds === 120) {
          // display an error message and continue waiting for the result
          Api.alertBox(
            'Error',
            'The backend failed to return a result for the exam submission within the expected time.',
          );
        }
        waitingSeconds++;
        setTimeout(SessionNotifier, 500);
      }
    } catch (error) {
      console.log(`session notifier job: error=${error}`);
      Sentry.captureException(error);
    }
  };

  // ---- onSessionComplete ----
  async function onSessionComplete(deviceDataId: string) {
    setWaiting(false);
    try {
      const session = await Api.remote.load_session(deviceDataId);

      if (session) {
        setSession(session);
      }
    } catch {
      Api.alertBox('Error', `Server Error`);
    }
  }

  if (waiting) {
    SessionNotifier();
  }

  return <></>;
};
export default SessionNotifier;
