import { t, TOptions } from 'i18next';
import { $Dictionary } from 'i18next/typescript/helpers';

enum Language {
  ENGLISH = 'English',
  SPANISH = 'Spanish',
  FRENCH = 'French',
  ITALIAN = 'Italian',
  GERMAN = 'German',
  // ARABIC = 'Arabic',
}

enum I18NextNS {
  COMMON = 'common',
  REPORT = 'report',
}

const getLanguageName = (lang: Language): string => {
  switch (lang) {
    case Language.ENGLISH:
    case Language.SPANISH:
    case Language.FRENCH:
    case Language.ITALIAN:
    case Language.GERMAN: {
      return lang;
    }
    default: {
      return `Unknown: ${lang}`;
    }
  }
};

const handleCommonT = (key: string | string[]) =>
  t(key, { ns: I18NextNS.COMMON });

const handleReportT = <TOpt extends TOptions>(
  key: string | string[],
  options?: TOpt & $Dictionary,
) => t(key, { ns: I18NextNS.REPORT, ...options });

function handleNSI18Next() {
  return {
    handleCommonT,
    handleReportT,
  };
}

export { getLanguageName, handleNSI18Next, I18NextNS, Language };
